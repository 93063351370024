.product-accordeon {
    padding:10px 0;
    border-bottom:1px solid #f1f3f4;
}
.product-accordeon.product-accordeon-view {
    margin:0;
    padding:0;
    border:none;
}
.product-accordeon .product-accordeon-content {
    margin:10px 0 5px;
}
.product-accordeon .product-accordeon-content .product-accordeon-item {
    display:flex;
    align-items:center;
    margin-top:10px;
}
.product-accordeon .product-accordeon-content .product-accordeon-item img {
    margin-right:10px;
}
.product-accordeon .product-accordeon-content .product-accordeon-item img:hover {
    cursor:pointer;
}
.product-accordeon.product-accordeon-hide .product-accordeon-content {
    display:none;
}
.product-accordeon .product-accordeon-title {
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor:pointer;
}
.product-accordeon h4 {
    margin:20px 0 5px;
}
.product-accordeon .product-accordeon-title h4 {
    margin:0 !important;
}
.product-accordeon .product-accordeon-title div div {
    font-size:90%;
    color:#6c757d;
}
.product-accordeon .product-accordeon-title div div span {
    color:#f44337;
}
.product-accordeon .product-accordeon-title .product-accordeon-arrow {
	width:36px;
    height:36px;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
	background:#f1f3f4 url(./Images/arrow-down.svg) no-repeat center center;
    transform:rotate(180deg);
}
.product-accordeon.product-accordeon-hide .product-accordeon-arrow {
	transform:rotate(0);
}
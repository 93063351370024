.modal.modal-popup-category {
	width:450px;
	height:700px;
	display:flex;
	flex-direction:column;
}
.modal.modal-popup-category .container-inner {
	height:calc(700px - 145px);
	width:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-popup-category .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row {
	padding:5px 0;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row-select {
	margin:5px 0;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row-plus img.categoty-add {
	height:51px;
	margin-left:8px;
	cursor:pointer;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row label {
	width:140px;
	color:#4a4d4f;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row label.label-middle {
	width:390px;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row input,
.modal.modal-popup-category .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row input:focus,
.modal.modal-popup-category .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row textarea.lite {
	height:80px;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row input::placeholder,
.modal.modal-popup-category .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .input-title,
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .select.select-middle {
	width:200px;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .product-edit-area {
	padding-bottom:10px;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .product-edit-area:last-child {
	padding-bottom:0;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row .checkbox {
	margin-right:10px;
	cursor:pointer;
}
.modal.modal-popup-category .product-edit .container-inner .product-edit-row-oneline-start {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:flex-start;
}
.modal.modal-popup-category .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.modal.modal-popup-category .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.modal.modal-popup-category .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.modal.modal-popup-category .product-edit .product-edit-footer .btn-save {
	background:#000;
	color:#fff;
}

.modal.modal-popup-items {
	width:350px;
}
.modal.modal-popup-items.modal-popup-items-wide {
	width:400px;
}
.modal.modal-popup-items .weights-form {
	margin-top:10px;
	padding:5px 0 1px;
	border-top:1px solid #f2f2f3;
}
.modal.modal-popup-items .weights-form .weights-form-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:10px 0;
}
.modal.modal-popup-items .weights-form .weights-form-row label {
	color:#4a4d4f;
}
.modal.modal-popup-items .weights-form .weights-form-row label span {
	display:block;
	color:#999;
	font-size:90%;
}
.modal.modal-popup-items .weights-form .weights-form-row div {
	position:relative;
}
.modal.modal-popup-items .weights-form .weights-form-row div span {
	display:inline-block;
	position:absolute;
	top:10px;
	right:6px;
	padding:3px 8px;
	font-size:85%;
	color:#4a4d4f;
	border-radius:5px;
	background:#f2f2f3;
}
.modal.modal-popup-items .weights-form .weights-form-row div input {
	width:140px;
	padding-right:45px;
	text-align:right;
}
.modal.modal-popup-items .buttons .btn-accept,
.modal.modal-popup-items .buttons .btn-cancel.btn-select {
	width:100%;
}
.modal.modal-popup-items .buttons .btn-cancel.btn-select {
	margin-bottom:10px;
}
.modal.modal-popup-items-wide .buttons .btn-cancel.btn-select-half1 {
	width:100% !important;
}
.modal.modal-popup-items-wide .buttons .btn-cancel.btn-select-half2 {
	width:49% !important;
}
.modal.modal-popup-items-wide .buttons .btn-cancel.btn-select-half3 {
	width:32.5% !important;
}
.modal.modal-popup-items-wide .buttons .btn-cancel.btn-select-half1,
.modal.modal-popup-items-wide .buttons .btn-cancel.btn-select-half2,
.modal.modal-popup-items-wide .buttons .btn-cancel.btn-select-half3 {
	padding:14px 0 !important;
}
.modal.modal-popup-items-wide .buttons .btn-select-half-select {
	border:1px solid #ffc060;
	background:#fff;
}

.modal.modal-popup-conditions-lite {
	width:800px;
	height:600px;
	display:flex;
	flex-direction:column;
}
.modal.modal-popup-conditions-lite .header-sub {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.modal.modal-popup-conditions-lite .header-sub img.add {
	width:24px;
	height:24px;
	margin-left:10px;
	cursor:pointer;
}
.modal.modal-popup-conditions-lite .conditions {
	height:608px;
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:space-between;
	border-radius:16px;
}
.modal.modal-popup-conditions-lite .conditions .sections {
	width:250px;
	height:calc(600px - 90px);
	border-radius:16px;
	background:#f2f2f3;
}
.modal.modal-popup-conditions-lite .conditions .sections .inner {
	height:calc(100% - 56px);
	overflow-y:auto;
	padding:10px;
	padding-right:5px;
}
.modal.modal-popup-conditions-lite .conditions .sections .inner .list {
	height:100%;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-popup-conditions-lite .conditions .sections .inner .list .section {
	display:block;
	position:relative;
	margin-bottom:4px;
	margin-right:5px;
	padding:10px 15px;
	border-radius:10px;
	color:#000;
	text-decoration:none;
	cursor:pointer;
}
.modal.modal-popup-conditions-lite .conditions .sections .inner .list .section:hover {
	background:#e5e6e6;
}
.modal.modal-popup-conditions-lite .conditions .sections .inner .list .section:hover.section-select {
	background:#fff;
}
.modal.modal-popup-conditions-lite .conditions .sections .inner .list .section-select {
	background:#fff;
} 
.modal.modal-popup-conditions-lite .conditions .sections .sections-footer {
	border-top:1px solid #e5e6e6;
}
.modal.modal-popup-conditions-lite .conditions .sections .sections-footer button {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin:0 auto;
	padding:15px;
	text-align:center;
	border:none;
	background:none;
	cursor:pointer;
}
.modal.modal-popup-conditions-lite .conditions .sections .sections-footer button img {
	margin-right:8px;
}
.modal.modal-popup-conditions-lite .conditions .sections .category-empty {
	display:flex;
	flex-direction:column;
	justify-content:flex-end;
	align-items:center;
	height:100%;
	padding:0 30px 20px;
}
.modal.modal-popup-conditions-lite .conditions .sections .category-empty div {
	margin-bottom:30px;
	text-align:center;
}
.modal.modal-popup-conditions-lite .conditions .sections .category-empty img {
	margin-left:-20px;
}
.modal.modal-popup-conditions-lite .conditions .content {
	width:calc(100% - 230px);
	height:100%;
	padding:0 0 0 20px;
}
.modal.modal-popup-conditions-lite .conditions .content .components-list {
	width:100%;
	height:calc(600px - 90px);
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-popup-conditions-lite .conditions .content .components-list .component {
	padding:16px 5px 16px 5px;
	border-bottom:1px solid #e5e6e6;
	cursor:pointer;
}
.modal.modal-popup-conditions-lite .conditions .content .components-list .component:hover {
	background:#f2f2f3;
	transition:all .2s;
}
.modal.modal-popup-conditions-lite .conditions .content .components-list .component span {
	display:block;
	font-size:90%;
	color:#666;
}
.modal.modal-popup-conditions-lite .conditions .content .empty {
	margin-top:140px;
}
.modal.modal-popup-conditions-lite .conditions .content .empty .product-add-container {
	margin-top:10px;
}
.modal.modal-popup-conditions-lite .conditions .content .empty .product-add-container p {
	margin-bottom:10px;
	color:#4a4d4f;
}
.modal.modal-popup-conditions-lite .conditions .content .empty .product-add-container button {
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}

.modal.modal-popup-component-newadd {
	width:450px;
	height:630px;
	display:flex;
	flex-direction:column;
}
.modal.modal-popup-component-newadd .container-inner {
	height:calc(630px - 145px);
	width:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-popup-component-newadd .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row {
	padding:5px 0;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row-select {
	margin:5px 0;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row-plus img.categoty-add {
	height:51px;
	margin-left:8px;
	cursor:pointer;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row label {
	width:140px;
	color:#4a4d4f;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row label.label-middle {
	width:390px;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row input,
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row input:focus,
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row textarea.lite {
	height:80px;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row input::placeholder,
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row .input-title,
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row .select.select-middle {
	width:200px;
}
.modal.modal-popup-component-newadd .product-edit .container-inner .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.modal.modal-popup-component-newadd .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.modal.modal-popup-component-newadd .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.modal.modal-popup-component-newadd .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.modal.modal-popup-component-newadd .product-edit .product-edit-footer .btn-save {
	background:#000;
	color:#fff;
}

.modal.modal-popup-products-list {
	width:500px;
	height:600px;
	display:flex;
	flex-direction:column;
}
.modal.modpopup-al-products-list .btn-accept {
	width:100%;
}
.modal.modal-popup-products-list input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.modal.mpopup-odal-products-list input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modpopup-al-products-list .products {
	height:100%;
}
.modal.modal-popup-products-list .products .list {
	height:464px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-popup-products-list .products .list .product {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:16px 5px 16px 5px;
	border-bottom:1px solid #e5e6e6;
}
.modal.modal-popup-products-list .products .list .product div span {
	display:block;
	font-size:90%;
	color:#666;
}
.modal.modal-popup-products-list .products .list .product-empty {
	margin-top:40px;
	text-align:center;
}

.modal.modal-popup-semifinishes {
	width:420px !important;
	height:500px;
	display:flex;
	flex-direction:column;
}
.modal.modal-popup-semifinishes .product-semifinish {
	cursor:pointer;
}
.modal.modal-popup-semifinishes .product-semifinish:hover {
	background:#f2f2f3;
}
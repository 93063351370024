.layout-container .components-categories-list .categories-list-container {
	height:calc(100vh - 220px);
	display:flex;
	flex-direction:column;
	border-radius:12px;
	background:#f2f2f3;
}
.layout-container .components-categories-list .categories-list-container .container-inner {
	height:100%;
	overflow-y:auto;
	padding:10px;
}
.layout-container .components-categories-list .categories-list-container .container-inner .list {
	height:100%;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.layout-container .components-categories-list .categories-list-container .container-inner .list .category-empty {
	display:flex;
	flex-direction:column;
	justify-content:flex-end;
	align-items:center;
	height:calc(100% - 40px);
	padding:0 30px 20px;
}
.layout-container .components-categories-list .categories-list-container .container-inner .list .category-empty div {
	margin-bottom:30px;
	text-align:center;
}
.layout-container .components-categories-list .categories-list-container .container-inner .list .category-empty img {
	margin-left:-20px;
}
.layout-container .components-categories-list .categories-list-container .categories-list-footer {
	border-top:1px solid #e5e6e6;
}
.layout-container .components-categories-list .categories-list-container .categories-list-footer button {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin:0 auto;
	padding:15px;
	text-align:center;
	border:none;
	background:none;
	cursor:pointer;
}
.layout-container .components-categories-list .categories-list-container .categories-list-footer button img {
	margin-right:8px;
}
.layout-container .components-categories-list .categories-list-container .category {
	position:relative;
	margin-bottom:4px;
	padding:10px 15px;
	border-radius:10px;
	cursor:pointer;
}
.layout-container .components-categories-list .categories-list-container .category.category-hide {
	color:#999;
	background:url('./Images/eye-off-grey.svg') no-repeat center right 10px;
}
.layout-container .components-categories-list .categories-list-container .category:hover {
	background:#e5e6e6;
}
.layout-container .components-categories-list .categories-list-container .category:hover.category-select {
	background:#fff;
}
.layout-container .components-categories-list .categories-list-container .category-select {
	background:#fff;
}
.layout-container .components-categories-list .categories-list-container .category .category-edit {
	display:none;
	position:absolute;
	right:5px;
	top:5px;
	width:30px;
	height:30px;
	border-radius:8px;
	background:#f2f2f3 url('./Images/edit.svg') no-repeat 50% 50%;
	cursor:pointer;
}
.layout-container .components-categories-list .categories-list-container .category:hover .category-edit {
	display:block;
}
.newpassword-container {
	position:relative;
	width:500px;
	margin:140px auto 0;
	padding:40px 50px;
	background:#fff;
	border-radius:20px;
	box-shadow:0 2px 10px rgba(37,48,62,.15);
}
.newpassword-container img {
	height:30px;
	margin:0 0 30px;
	display:block;
}
.newpassword-container .form-column {
	display:block;
	margin-bottom:15px;
}
.newpassword-container .form-column label {
	margin:20px 0;
	position:relative;
}
.newpassword-container .form-column label:hover .input-panel {
	cursor:pointer;
	border-color:#999;
}
.newpassword-container .form-column label span.placeholder {
	margin-left:20px;
	position:absolute;
	left:0;
	top:-12px;
	transition:.2s;
	transition-timing-function:ease;
	transition-timing-function:cubic-bezier(.25, .1, .25, 1);
	opacity:.4;
	color:#000;
	cursor:pointer;
	white-space:nowrap;
}
.newpassword-container .form-column .input-panel {
	padding:30px 50px 10px 20px;
	width:100%;
	border-radius:12px;
	border:1px solid #ccc;
	font-size:110%;
}
.newpassword-container .form-column .input-panel:focus,
.newpassword-container .form-column label:hover .input-panel:focus {
	border:2px solid #21add9;
	padding:29px 49px 9px 19px;
}
.newpassword-container .form-column .input-panel:focus+span.placeholder,
.newpassword-container .form-column .input-panel:not(:placeholder-shown)+span.placeholder {
	opacity:1;
	color:#777;
	transform:translateY(-10px) translateX(0);
	font-size:80%;
}
.newpassword-container .form-column .input-panel:focus+span.placeholder,
.newpassword-container .form-column .input-panel:not(:-ms-input-placeholder)+span.placeholder {
	opacity:1;
	color:#777;
	transform:translateY(-10px) translateX(0);
	font-size:80%;
}
.newpassword-container .form-column .input-panel[iserror=true],
.newpassword-container .form-column .input-panel[iserror=true]:focus,
.newpassword-container .form-column label:hover .input-panel[iserror=true] {
	border-color:#ff402b;
	background-color:#fff9f8;
	background:url(./Images/attention.svg) 99% 9px no-repeat;
}
.newpassword-container .buttons {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}
.newpassword-container .buttons button.btn {
	margin-top:10px;
	border-radius:12px;
    background:#122034;
	color:#fff;
	transition:.3s;
	text-transform:uppercase;
	border:none;
	padding:20px 40px;
	font-size:100%;
}
.newpassword-container .buttons button.btn:hover {
	cursor:pointer;
	opacity:.8;
}
.newpassword-container .buttons .forgot {
	margin:20px 0 0;
	text-align:right;
}
.newpassword-container .buttons .forgot span {
	display:block;
	color:#333;
}
.newpassword-container .buttons .forgot a {
	color:#21add9;
}
.newpassword-container .buttons .forgot a:hover {
	text-decoration:none;
}
.newpassword-container .success {
	margin:40px 0 0;
}
.newpassword-container .success div {
	margin-bottom:10px;
	font-size:120%;
}
.newpassword-container .success a {
	color:#21add9;
}

@media (max-width: 768px) {
	.newpassword-container {
		width: 90%;
		margin: auto;
	}
}
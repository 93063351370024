.inventory-container {
	position:relative;
	width:100%;
	height:100%;
}
.inventory-container .controls {
	display:flex;
	align-items:center;
	padding:10px;
	border-radius:12px;
	background:#f2f2f3;
}
.inventory-container .controls input {
	width:200px;
	margin-right:10px;
	padding:11px 15px;
	border-radius:8px;
	border:none;
	background:#fff;
}
.inventory-container .controls .title {
	margin-left:10px;
	padding:9px 15px;
	border-radius:8px;
	border:none;
	background:#007bff30;
}
.inventory-container .tab {
	margin-right:10px;
	padding:9px 15px;
	border-radius:8px;
	border:none;
	background:#fff;
	cursor:pointer;
}
.inventory-container .tab.tab-active {
	background:#007bff30;
}
.inventory-container .controls .buttons {
	display:flex;
	align-items:center;
	margin-left:auto;
}
.inventory-container .controls .buttons button {
	padding:11px 20px;
	font-size:100%;
	border-radius:8px;
	border:none;
	background:#ffc060;
	cursor:pointer;
}
.inventory-container .controls .buttons img {
	margin-left:20px;
	padding:6px;
	border-radius:10px;
	background:#fff;
	cursor:pointer;
}
.inventory-container .controls .buttons img:hover {
	background:#ccc;
	transition:.3s;
}
.inventory-container .items-container {
	height:calc(100% - 70px);
	width:100%;
	margin-top:10px;
    padding:0 0 10px 0;
    overflow-y: auto;
    scrollbar-color:#bbb transparent;
    scrollbar-width:thin;
}
.inventory-container .items-container table,
.inventory-container .items-container table thead tr th,
.inventory-container .items-container table tbody tr td {
	cursor:default !important;
}
.inventory-container .items-container table.items-invenrory-list tr td {
	cursor:pointer !important;
}
.inventory-container .items-container table tr.section {
	background:#eee;
}
.inventory-container .items-container table tr.section td {
	padding-left:10px !important;
	font-weight:bold;
}
.inventory-container .items-container table tr.section:hover {
	background:#eee !important;
}
.inventory-container .items-container table tr.section-lite,
.inventory-container .items-container table tr.section-lite:hover {
	background:none !important;
}
.inventory-container .items-container table tr.section-lite td {
	padding-left:5px !important;
}
.inventory-container .items-container table tbody tr td input {
	width:70px;
	padding:8px;
	text-align:right;
	border-radius:8px;
	border:1px solid transparent;
	background:#f8f8f8;
}
.inventory-container .items-container table tbody tr td input:focus {
	border:1px solid #ccc;
	background:#fff;
}
.inventory-container .items-container table.items-no-control-full {
	margin-bottom:40px;
}
.inventory-container .inventory-results {
	position:absolute;
	bottom:0;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	padding:10px;
	background:#f6f6f6;
}
.inventory-container .inventory-results div {
	margin-left:10px;
	color:#666;
}
.inventory-container .inventory-results div b {
	color:#000;
}

.modal.modal-storage {
	width:500px;
}
.modal.modal-storage .storages {
	margin-bottom:60px;
}
.modal.modal-storage .storages .item {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:10px 5px;
	border-bottom:1px solid #e5e6e6;
}
.modal.modal-storage .storages .item div span {
	display:block;
	font-size:85%;
	color:#666;
}
.modal.modal-storage .storages .item img {
	cursor:pointer;
}
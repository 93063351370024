.ticket-list .ticket {
	padding:10px 0;
	border-bottom:1px solid #f2f2f3;
}
.ticket-list .ticket .ticket-info {
	text-align: right;
	margin-bottom:10px;
}
.ticket-list .ticket .ticket-info .ticket-user {
	font-size:90%;
	font-weight:bold;
	color:#666;
}
.ticket-list .ticket .ticket-info .ticket-user span {
	font-weight:normal;
}
.ticket-list .ticket .ticket-info .ticket-date {
	font-size:80%;
	color:#666;
}
.ticket-list .ticket .ticket-body {
	display:flex;
	flex-direction:column;
	width:100%;
	padding:10px 0;
}
/* reset */

html,body,ul,ol,li,dl,dt,dd,p,pre,h1,h2,h3,h4,h5,h6,blockquote,form,fieldset,legend,table,td,th {margin:0;padding:0;}
html,body {width:100%;height:100%;}
body {overflow-x:hidden;min-height:100%;background:#f8f8f8;color:#000;font:14px/1.5 Arial,'Helvetica Neue',Helvetica,sans-serif;display:flex;flex-direction:column;height:100vh;}
html {scroll-behavior:smooth;}

img {margin:0;border-width:0;padding:0;}
ul {list-style:none;}

* {box-sizing:border-box;outline:none;}
* {touch-action:manipulation;}

h1,h2,h3,h4.h5 {letter-spacing:-.03em;line-height:1.032em;font-weight:700;}

h1 {font-size:32px;}
h2 {font-size:26px;font-weight:700;}
h3 {font-size:20px;}
h4 {font-size:16px;font-weight:600;}
h5 {font-size:14px;font-weight:600;}

#root {display:flex;flex-direction:column;height:100%;width:1240px;margin:0 auto;}

body {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

/* others styles */

/* modal */

.modal {
    width:400px;
	padding:24px;
	border-radius:16px;
	background:#fff;
}
.modal .header {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	margin-bottom:20px
}
.modal .header .btn-close {
	cursor:pointer;
}
.modal p {
	margin-bottom:10px;
}
.modal input,
.modal textarea {
	width:100%;
	padding:12px 16px;
	font-size:100%;
	border-radius:12px;
	background:#fff;
	border:1px solid #b0b3b5;
	resize:none;
}
.modal .buttons {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	margin-top:20px;
}
.modal .buttons button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	border:none;
	cursor:pointer;
}
.modal .buttons .btn-cancel {
	color:#1e1f20;
	background:#f2f2f3;
}
.modal .buttons .btn-accept {
	color:#fff;
	background:#000;
}
.modal .buttons .btn-accept.btn-delete {
	background:#c00;
}

/* print css */

@media print {

}
.modal.modal-day {
	width:400px;
}
.modal.modal-day .day-info {
	margin-bottom:10px;
	text-transform:capitalize;
}
.modal.modal-day .day-info span {
	margin-left:10px;
	text-transform:capitalize;
	color:#96999c;
}
.modal.modal-day .header img {
	position:relative;
	top:-35px
}
.modal.modal-day .container-inner .user-info {
	margin-bottom:20px;
}
.modal.modal-day .container-inner .user-info span {
	display:block;
	font-size:90%;
	color:#96999c;
}
.modal.modal-day .container-inner .worktime {
	padding:5px 0 1px;
	border-top:1px solid #f2f2f3;
}
.modal.modal-day .container-inner .worktime .worktime-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:10px 0 30px;
}
.modal.modal-day .container-inner .worktime .worktime-row label {
	color:#4a4d4f;
	margin-right:10px;
}
.modal.modal-day .container-inner .worktime .worktime-row div {
	position:relative;
}
.modal.modal-day .container-inner .worktime .worktime-row div input {
	width:110px;
	text-align:right;
}
.modal.modal-day .container-inner .worktime .worktime-row div input.date {
	width:160px;
	text-align:right;
}
.modal.modal-day .worktime-reset {
	display:block;
	width:140px;
	margin:0 0 30px auto;
	padding:5px 10px;
	border:1px solid #f2f2f3;
	border-radius:10px;
	background:#f2f2f3;
	text-align:center;
	color:#4a4d4f;
	cursor:pointer;
}
.modal.modal-day .worktime-reset:hover {
	color:#fff;
	background:#f44337;
}
.modal.modal-day .notice {
	display:block;
	padding:10px 15px;
	color:#4a4d4f;
	font-size:95%;
	font-style:italic;
	border-radius:8px;
	background:#fffcc0;
}

.modal.modal-salary {
	width:350px;
}
.modal.modal-salary .user-info {
	margin-bottom:10px;
}
.modal.modal-salary .user-info span {
	display:block;
	font-size:90%;
	color:#96999c;
}
.modal.modal-salary .weights-form {
	margin-top:10px;
	padding:5px 0 1px;
	border-top:1px solid #f2f2f3;
}
.modal.modal-salary .weights-form .weights-form-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:10px 0;
}
.modal.modal-salary .weights-form .weights-form-row label {
	color:#4a4d4f;
}
.modal.modal-salary .weights-form .weights-form-row label span {
	display:block;
	color:#999;
	font-size:90%;
}
.modal.modal-salary .weights-form .weights-form-row div {
	position:relative;
}
.modal.modal-salary .weights-form .weights-form-row div span {
	display:inline-block;
	position:absolute;
	top:10px;
	right:6px;
	padding:3px 8px;
	font-size:85%;
	color:#4a4d4f;
	border-radius:5px;
	background:#f2f2f3;
}
.modal.modal-salary .weights-form .weights-form-row div input {
	width:140px;
	padding-right:45px;
	text-align:right;
}
.modal.modal-salary .buttons .btn-accept,
.modal.modal-salary .buttons .btn-select {
	width:100%;
}
.modal.modal-salary .buttons .btn-select {
	margin-bottom:10px;
}

.modal.modal-salary-calc {
	width:540px;
}
.modal.modal-salary-calc .container-inner .params {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.modal.modal-salary-calc .container-inner .params .param {
	margin:0 5px 5px 0;
	padding:4px 10px 3px;
	font-size:90%;
	border-radius:8px;
	background:#f2f2f3;
	cursor:pointer;
}
.modal.modal-salary-calc .container-inner .params .param:hover {
	background:#caccce;
}
.modal.modal-salary-calc .container-inner .select-block {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:5px 0;
}
.modal.modal-salary-calc .formula-result {
	margin:10px 0 30px;
	padding:15px 20px;
	border-radius:12px;
	background:#f2f2f3;
}
.modal.modal-salary-calc .formula-result h5 {
	margin-bottom:10px;
}
.modal.modal-salary-calc .formula-result h5 span {
	display:block;
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
}
.modal.modal-salary-calc .formula-result code {
	font-size:90%;
}
.modal.modal-salary-calc .formula-result .formula-row {
	display:inline-block;
	margin:5px 20px 0 0;
	padding:4px 6px 4px 12px;
	border:1px solid #caccce;
	border-radius:12px;
}
.modal.modal-salary-calc .formula-result .formula-row .formula-row-inner {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.modal.modal-salary-calc .formula-result .formula-row:hover {
	background:#fff;
}
.modal.modal-salary-calc .formula-result .formula-row .remove {
    width:23px;
    height:23px;
	margin-left:20px;
	text-align:center;
	line-height:22px;
    color:#333;
    font-size:18px;
    border-radius:50%;
    background:#fff;
	cursor:pointer;
}
.modal.modal-salary-calc .formula-result .formula-row .remove:hover {
	color:#fff;
	background:#f00;
}
.modal.modal-salary-calc .formula-result .formula-row input {
	width:50px;
	margin-left:8px;
	padding:6px 8px;
	border-radius:8px;
	background:transparent;
}
.modal.modal-salary-calc .formula-result .formula-row input.salary-input {
	width:80px;
}
.modal.modal-popup-component-add {
	width:450px;
	height:630px;
	display:flex;
	flex-direction:column;
}
.modal.modal-popup-component-add .container-inner {
	height:calc(630px - 145px);
	width:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-popup-component-add .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row {
	padding:5px 0;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row-select {
	margin:5px 0;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row-plus img.categoty-add {
	height:51px;
	margin-left:8px;
	cursor:pointer;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row label {
	width:140px;
	color:#4a4d4f;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row label.label-middle {
	width:390px;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row input,
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row input:focus,
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row textarea.lite {
	height:80px;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row input::placeholder,
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row .input-title,
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row .select.select-middle {
	width:200px;
}
.modal.modal-popup-component-add .product-edit .container-inner .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.modal.modal-popup-component-add .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.modal.modal-popup-component-add .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.modal.modal-popup-component-add .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.modal.modal-popup-component-add .product-edit .product-edit-footer .btn-save {
	background:#000;
	color:#fff;
}
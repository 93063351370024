.components-container {
	display:flex;
	flex-direction:row;
	padding:20px;
	height:100%;
}

.components-container .categories-container {
	display:flex;
	flex-direction:row;
	width:65%;
	border-right:1px solid #f2f2f3;
}

.components-container .categories-container .products-list {
	flex:1;
}
.components-container .categories-container .products-list .products-list-controls {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.components-container .categories-container .products-list .products-list-controls input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.components-container .categories-container .products-list .products-list-controls input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.components-container .categories-container .products-list .products-list-controls input.search.search-single {
	width:calc(100% - 10px);
}
.components-container .categories-container .products-list .container-inner {
	height:calc(100% - 70px);
	margin-top:20px;
	padding:10px;
	padding-top:0;
	padding-left:0;
	padding-right:0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .categories-container .products-list .products-empty {
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	height:calc(100% - 60px);
}
.components-container .categories-container .products-list .products-empty div {
	margin-top:260px;
	text-align:center;
	color:#4a4d4f;
}
.components-container .categories-container .products-list .products-empty img {
	position:absolute;
	top:20px;
	right:30px;
}
.components-container .categories-container .products-list table.items {
	width:100%;
	border-collapse:collapse;
}
.components-container .categories-container .products-list table.items thead {
	position:sticky;
	top:0;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
}
.components-container .categories-container .products-list table.items thead tr th,
.components-container .categories-container .products-list table.items tr td {
	padding:10px 0 10px 5px;
	text-align:left;
}
.components-container .categories-container .products-list table.items tr td {
	border-bottom:1px solid #f2f2f3;
}
.components-container .categories-container .products-list table.items tbody tr:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.components-container .categories-container .products-list table.items thead tr th {
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items .hidden {
	color:#aaa;
}
.components-container .categories-container .products-list table.items .money {
	width:70px;
	text-align:right;
}
.components-container .categories-container .products-list table.items .control {
	width:30px;
}
.components-container .categories-container .products-list table.items .control img {
	display:block;
	margin-left:auto;
	cursor:pointer;
}
.components-container .categories-container .products-list table.items .control .control-block {
	position:absolute;
	border-radius:8px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	padding:10px 0;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items .row-oneline-wide {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .categories-container .products-list table.items .info-row,
.components-container .categories-container .products-list table.items .info-row:hover {
	background: #fafafa !important;
	cursor:default !important;
}
.components-container .categories-container .products-list table.items .info-row-last {
	background:transparent !important;
}
.components-container .categories-container .products-list table.items .control .control-block div {
	padding:10px 50px 10px 20px;
}
.components-container .categories-container .products-list table.items .control .control-block div.plus-operation {
	background:url('./Images/plus-green.svg') no-repeat center right 10px;
}
.components-container .categories-container .products-list table.items .control .control-block div.minus-operation {
	background:url('./Images/minus-red.svg') no-repeat center right 10px;
}
.components-container .categories-container .products-list table.items .control .control-block div.worktime {
	background:url('./Images/calendar.svg') no-repeat center right 10px;
}
.components-container .categories-container .products-list table.items .control .control-block div.profile {
	background:url('./Images/user.svg') no-repeat center right 10px;
}
.components-container .categories-container .products-list table.items .control .control-block div:hover {
	color:#000;
	background-color:#f2f2f3;
}

.components-container .product-edit {
	display:flex;
	width:35%;
}
.components-container .product-edit .product-add-container {
	margin-top:10px;
}
.components-container .product-edit .product-add-container p {
	margin-bottom:10px;
	color:#4a4d4f;
}
.components-container .product-edit .product-add-container button {
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-list-container {
	display:flex;
	flex-direction:column;
	width:100%;
	margin-left:10px;
}
.components-container .product-edit .container-inner {
	width:100%;
	height:100%;
	overflow-y:auto;
}
.components-container .product-edit .container-inner .list {
	height:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.components-container .product-edit .container-inner .product-edit-row {
	padding:10px 0;
}
.components-container .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .product-edit .container-inner .product-edit-row label {
	width:150px;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-edit-row label.label-middle {
	width:280px;
}
.components-container .product-edit .container-inner .product-edit-row input,
.components-container .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.components-container .product-edit .container-inner .product-edit-row input:focus,
.components-container .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.components-container .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.components-container .product-edit .container-inner .product-edit-row input::placeholder,
.components-container .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.components-container .product-edit .container-inner .product-edit-row .input-title,
.components-container .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.components-container .product-edit .container-inner .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.components-container .product-edit .container-inner .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.components-container .product-edit .container-inner .product-edit-row .select.select-middle {
	width:200px;
}
.components-container .product-edit .container-inner .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn {
	display:inline-block;
	margin-right:10px;
	padding:8px 24px;
	border:none;
	border-radius:8px;
	color:#1e1f20;
	font-size:100%;
	background:#eee;
	cursor:pointer;
	text-decoration:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn:hover {
	background:#ccc;
	transition:all 1s;
}
.components-container .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.components-container .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.components-container .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-footer .btn-save {
	background:#ffc060;
}
.components-container .product-view-row .date-upper {
	text-transform:capitalize;
}
.components-container .salary-list-buttons {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:10px 0;
}
.components-container .salary-list-buttons button {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	padding:10px 0;
	width:49%;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}
.components-container .salary-list-buttons button img {
	margin-right:5px;
}
.components-container .salary-list-buttons button:hover {
	background:#e5e6e6;
}
.components-container .salary-list .salary-list-row {
	display:flex;
	flex-direction:row;
	padding:4px 0 4px 4px;
	border-bottom:1px solid #f2f2f3;
}
.components-container .salary-list .salary-list-row:hover {
	background:#f2f2f3;
}
.components-container .salary-list .salary-list-row .salary-list-row-name span {
	display:block;
	font-size:90%;
	color:#96999c;
}
.components-container .salary-list .salary-list-row .salary-list-row-amount {
	margin-left:auto;
	text-align:right;
}
.components-container .salary-list .salary-list-row .salary-list-row-control {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	width:74px;
	height:24px;
	margin-left:10px;
	padding-top:2px;
	font-size:85%;
	font-weight:bold;
	color:#fff;
	border-radius:8px;
	background:#1e1f20;
	cursor:pointer;
}
.components-container .salary-list .salary-list-row .salary-list-row-control:hover {
	background:#555;
}
.components-container .salary-list .salary-list-row .salary-list-row-control.salary-list-row-control-unpay {
	color:#1e1f20;
	background:#f2f2f3;
}
.components-container .salary-list .salary-list-row .salary-list-row-control.salary-list-row-control-unpay:hover {
	color:#fff;
	background:#e95352;
}
.components-container .salary-list .salary-list-row .salary-list-row-delete {
	width:30px;
	text-align:right;
}
.components-container .salary-list .salary-list-row .salary-list-row-delete img {
	width:20px;
	cursor:pointer;
}

.modal.modal-expense {
	width:420px;
}
.modal.modal-expense .buttons button {
	padding:14px 0;
	width:32%;
}

.modal.modal-salary-operation {
	width:400px;
}
.modal.modal-salary-operation .container-inner .user-info {
	margin-bottom:10px;
	padding:0 5px 10px;
	border-bottom:1px solid #f2f2f3;
}
.modal.modal-salary-operation .container-inner .user-info span {
	display:block;
	font-size:90%;
	color:#96999c;
}
.modal.modal-salary-operation .container-inner .row {
	padding:10px 0;
}
.modal.modal-salary-operation .container-inner .row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.modal.modal-salary-operation .container-inner .row label {
	width:250px;
}
.modal.modal-salary-operation .container-inner .row input {
	width:120px;
}
.modal.modal-salary-operation .container-inner .row input.wide {
	width:100%;
}
.modal.modal-salary-operation .select {
	padding:8px 10px;
	border:1px solid #b0b3b5;
	border-radius:12px;
}
.modal.modal-salary-operation select {
	width:100%;
	border:none;
	background:none;
}
.product-edit-row-addresses {
	position:relative;
}
.point-addresses-list {
	position:absolute;
	left:3px;
	width:calc(100% - 6px);
	max-height:300px;
	padding:10px 0;
	color:#4a4d4f;
	border-radius:8px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	border:1px solid #f2f2f3;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.point-addresses-list div {
	padding:10px 50px 10px 20px;
}
.point-addresses-list div:hover {
	color:#000;
	background-color:#f2f2f3;
	cursor:pointer;
}

.modal.modal-map {
	width:1000px;
	height:700px;
	padding:0;
	overflow:hidden;
}
.modal.modal-map .map-container {
	height:100%;
}
.modal.modal-map .map-icon {
	position:absolute;
	top:15px;
	right:15px;
	width:40px;
	height:40px;
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow:0 0 10px rgba(0,0,0,.1);
	border-radius:50%;
	background:#fff;
	cursor:pointer;
}
.modal.modal-map .map-icon.map-close {
	right:15px;
}
.modal.modal-map .map-icon.map-edit {
	right:65px;
}
.setup-container-container {
	position:relative;
	width:1200px;
	height:calc(100% - 15px);
	margin:0 auto;
}
.setup-container {
	position:relative;
	width:550px;
	height:100%;
	margin:15px auto 0;
	background:#fff;
	border-radius:20px 20px 0 0;
	box-shadow:0 0 10px rgba(37,48,62,.05);
}
.setup-container-menu {
	position:absolute;
	top:15px;
	right:10px;
	width:300px;
	max-height:calc(100% - 15px);
	border-radius:20px;
	background:#fff;
	box-shadow:0 0 10px rgba(37,48,62,.05);
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.setup-container-menu ul {
	margin:20px 0;
	padding:0;
	list-style:none;
}
.setup-container-menu ul li {
	padding:9px 0 7px 20px;
	color:#4a4d4f;
	border-bottom:1px solid #f2f2f3;
}
.setup-container-menu ul li.active,
.setup-container-menu ul li:hover {
	color:#000;
	background:#f2f2f3;
	cursor:pointer;
}
.setup-container-totop {
	position:absolute;
	bottom:0;
	left:260px;
	width:46px;
	height:46px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:160%;
	font-weight:bold;
	color:#666;
	border-radius:10px;
	background:#fff;
	box-shadow:0 0 10px rgba(37,48,62,.05);
	cursor:pointer;
}
.setup-container-totop:hover {
	color:#000;
	background:#f2f2f3;
}
.setup-container .loader {
	margin:10px 0 20px 10px;
}
.setup-container .header {
	position:sticky;
	top:15px;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:25px;
	border-bottom:1px solid #f0f0f0;
}
.setup-container .header img {
	height:25px;
	display:block;
}
.setup-container .header .steps {
	line-height:1.2;
	text-align:right;
}
.setup-container .header .steps label {
	display:block;
	font-weight:normal;
	font-size:105%;
}
.setup-container .header .steps div {
	color:#666;
}
.setup-container .header .steps div b {
	color:#333;
}
.setup-container .main {
	height:calc(100% - 15px - 76px - 65px);
	background:#fff;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.setup-container .main h3 {
	margin:0;
	padding:10px 10px 8px 25px;
	font-size:18px;
	background:#f2f2f3;
}
.setup-container .main .section {
	padding:25px;
	border-bottom:1px solid #f0f0f0;
}
.setup-container .main .section:last-child {
	border-bottom:none;
}
.setup-container .main .section h4 {
	position:relative;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:0 0 0 5px;
}
.setup-container .main .section h4.no-margin {
	margin:0;
}
.setup-container .main .section h4.margin-bottom {
	margin-bottom:15px;
}
.setup-container .main .section h4 img {
	cursor:pointer;
}
.setup-container .main .section h4 .help-desk {
	position:absolute;
	top:17px;
	right:-2px;
	width:260px;
	margin-top:10px;
	border-radius:8px;
	background:#fff;
	box-shadow:0 0 20px rgba(37,48,62,.2);
	z-index:1000;
}
.setup-container .main .section h4 .help-desk .help-desk-content,
.setup-container .main .section h4 .help-desk .help-desk-footer {
	color:#4a4d4f;
	font-size:80%;
	font-weight:normal;
	padding:10px;
}
.setup-container .main .section h4 .help-desk .help-desk-footer {
	padding:5px 10px 10px;
	border-top:1px solid #f0f0f0;
}
.setup-container .main .section h4 .help-desk .help-desk-footer a {
	color:#777;
	font-size:95%;
}
.setup-container .main .section h4 .help-desk .help-desk-footer a:hover {
	color:#4a4d4f;
}
.setup-container .main .section h4 .help-desk .help-desk-close {
	position:absolute;
	top:3px;
	right:2px;
	width:16px;
	height:16px;
	cursor:pointer;
}
.setup-container .footer {
	position:fixed;
	bottom:0;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:550px;
	padding:10px;
	border-top:1px solid #f0f0f0;
	background:#fff;
}
.setup-container .footer .buttons {
	width:50%;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.setup-container .footer button {
	width:49%;
	height:48px;
	padding:0 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.setup-container .footer .buttons .btn-cancel {
	background:#f2f2f3;
}
.setup-container .footer .buttons .btn-skip {
	background:#f2f2f3;
}
.setup-container .footer .buttons .btn-delete {
	color:#f00;
	background:transparent;
}
.setup-container .footer .btn-save {
	width:40%;
	background:#000;
	color:#fff;
}
.setup-container .footer .btn-done {
	width:40%;
	background:#ffc060;
	color:#000;
	text-transform:uppercase;
}
.setup-container .footer .btn-save.btn-wait {
	padding:11px 30px;
	color:#666;
	font-size:100%;
	text-align:center;
	border-radius:12px;
	background:#e0e0e0 url('./Images/loader.svg') no-repeat 20px 50%;
	background-size:32px;
	cursor:wait;
}
.setup-container .footer button:disabled {
	background:#ddd;
	color:#999;
	cursor:not-allowed;
}
.setup-container .footer button span {
	display:block;
	margin-top:3px;
	font-size:75%;
	color:#bbb;
}
.setup-container .product-edit-row {
	position:relative;
	padding:5px 0;
}
.setup-container .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.setup-container .product-edit-row label {
	width:140px;
	color:#4a4d4f;
}
.setup-container .product-edit-row label.label-middle {
	width:390px;
}
.setup-container .product-edit-row input,
.setup-container .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.setup-container .product-edit-row input:focus,
.setup-container .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.setup-container .product-edit-row textarea {
	height:120px;
}
.setup-container .product-edit-row textarea.lite {
	height:80px;
}
.setup-container .product-edit-row input::placeholder,
.setup-container .product-edit-row textarea::placeholder {
	color:#caccce
}
.setup-container .product-edit-row input:disabled {
	background:#eee;
	cursor:not-allowed;
}
.setup-container .product-edit-row input.time {
	width:49%;
}
.setup-container .product-edit-row input.color {
	width:100%;
    margin:0;
    padding:0;
	border:none;
}
.setup-container .product-edit-row .input {
	width:100%;
	padding:9px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
}
.setup-container .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.setup-container .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.setup-container .product-edit-row .select.select-middle {
	width:200px;
}
.setup-container .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.setup-container .product-edit-row .product-edit-btn {
	display:inline-block;
	margin-right:10px;
	padding:8px 24px;
	border:none;
	border-radius:8px;
	color:#1e1f20;
	font-size:100%;
	background:#eee;
	cursor:pointer;
	text-decoration:none;
}
.setup-container .product-edit-row button.product-edit-btn {
	padding:11px 24px;
}
.setup-container .product-edit-row .product-edit-btn:hover {
	background:#ccc;
	transition:all 1s;
}
.setup-container .product-view-row {
	padding:10px 0;
	border-bottom:1px solid #f2f2f3;
}
.setup-container .product-view-row-simple {
	padding:10px 0;
	border:none;
}
.setup-container .product-view-row-description {
	margin:0 0 5px 5px;
	font-size:90%;
	font-style:italic;
	color:#999;
}
.setup-container .bank-requisites {
	position:relative;
}
.setup-container .bank-requisites h4 {
	margin-top:20px !important;
}
.setup-container .bank-requisites .banks-list {
	position:absolute;
	bottom:60px;
	width:100%;
	min-height:100px;
	max-height:220px;
	background:#fff;
	border-radius:8px;
	border:1px solid #f2f2f3;
	box-shadow:0 0 10px rgba(37,48,62,.1);
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
	z-index:1000;
}
.setup-container .bank-requisites .banks-list .bank {
	padding:10px 20px;
	border-bottom:1px solid #f2f2f3;
	cursor:pointer;
}
.setup-container .bank-requisites .banks-list .bank:hover {
	background:#f2f2f3;
}
.setup-container .product-edit-row .product-edit-row-notice {
	font-size:90%;
	color:#666;
	margin:10px 5px;
}
.setup-container .product-edit-row .product-edit-row-notice pre {
	user-select:text;
}
.setup-container .product-edit-row .product-edit-row-notice div {
	margin-top:10px;
	font-size:95%;
	color:#999;
}
.setup-container .product-edit-row .product-edit-check {
	position:absolute;
	top:11px;
	right:6px;
	padding:10px 12px 9px;
	font-size:90%;
	color:#555;
	border-radius:8px;
	background:#f2f2f3;
	transition:.3s;
}
.setup-container .product-edit-row .product-edit-check:hover {
	background:#eee;
	cursor:pointer;
}
.setup-container .product-edit-row .product-edit-check-icon {
	position:absolute;
	top:19px;
	right:12px;
}
.setup-container .product-edit-row img.categoty-add {
	height:51px;
	margin-left:8px;
	cursor:pointer;
}
.setup-container .product-edit-row .product-edit-images {
	display:flex;
	flex-direction:row;
	align-items:center;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.setup-container .product-edit-row .product-edit-images .product-edit-image {
	position:relative;
	width:88px;
	height:88px;
	margin-right:10px;
	margin-bottom:10px;
	border-radius:12px;
	overflow:hidden;
}
.setup-container .product-edit-row .product-edit-images .product-edit-image img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.setup-container .product-edit-row .product-edit-images .product-edit-image .delete {
	position:absolute;
	top:calc(50% - 38px / 2);
	right:calc(50% - 38px / 2);
	width:38px;
	height:38px;
	background:url('./Images/delete-black-btn.svg') no-repeat 50% 50%;
	cursor:pointer;
}
.setup-container .product-edit-row .product-edit-images .product-edit-image-main {
	border:2px solid #2f8cff;
}
.setup-container .product-edit-row .product-edit-images .product-edit-image.product-edit-image-error .delete {
	display:none;
}
.setup-container .product-edit-row input[type="file"] {
	display:none;
}
.setup-container .product-view-list {
	margin:10px 0;
}
.setup-container .product-view-list .product-view-list-item {
	display:flex;
	flex-direction:row;
	margin-bottom:4px;
	padding:8px 10px;
	border-radius:8px;
}
.setup-container .product-view-list .product-view-list-item:nth-child(odd) {
	background:#f8f8f8;
}
.setup-container .product-view-list .product-view-list-item:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.setup-container .product-view-list .product-view-list-item .cell {
	flex-shrink:0;
}
.setup-container .product-view-list .product-view-list-item .cell-image {
	width:46px;
	height:46px;
	margin-right:15px;
	border-radius:12px;
	overflow:hidden;
}
.setup-container .product-view-list .product-view-list-item .cell-image img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.setup-container .product-view-list .product-view-list-item .cell-name {
	font-weight:bold;
}
.setup-container .product-view-list .product-view-list-item .cell-name span {
	display:block;
	font-size:90%;
	font-weight:normal;
	color:#666;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-price {
	width:90px;
	text-align:right;
	margin-left:auto;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-price s {
	color:#999;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-price span {
	color:#4a4d4f;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-measure {
	width:60px;
	color:#666;
	text-align:right;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-amount {
	width:70px;
	margin-left:15px;
	color:#666;
	text-align:right;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-amount-first {
	margin-left:auto;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-amount span {
	display:block;
	font-size:80%;
	font-weight:normal;
	color:#b0b3b5;
	line-height:1;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-color {
	width:40px;
}
.setup-container .product-view-list .product-view-list-item .cell.cell-color .color {
	width:16px;
	height:16px;
	margin:2px 0 0 auto;
	border-radius:50%;
}
.setup-container .product-view-list-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.setup-container .product-view-list p {
	margin:10px 0 20px;
	font-size:95%;
	font-style:italic;
	color:#4a4d4f;
}
.setup-container .product-view-list ul {
	margin-top:5px;
	list-style:disc;
}
.setup-container .product-view-list ul li {
	margin:5px 0 5px 20px;
}
.setup-container .product-view-list-check {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin:15px 0;
}
.setup-container .product-view-list-check img {
	width:20px;
	margin-right:10px;
}
.setup-container .product-view-list-check-summary {
	margin:20px 0 0;
	padding:10px 15px;
	font-size:95%;
	font-style:italic;
	color:#4a4d4f;
	border-radius:8px;
	background:#fffcc095;
}
.setup-container .product-view-list-check-summary b {
	color:#000;
}
.setup-container .product-edit-row-addresses {
	position:relative;
}
.setup-container .point-addresses-list {
	position:absolute;
	left:3px;
	width:calc(100% - 6px);
	max-height:300px;
	padding:10px 0;
	color:#4a4d4f;
	border-radius:8px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	border:1px solid #f2f2f3;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
	z-index:1000;
}
.setup-container .point-addresses-list div {
	padding:10px 50px 10px 20px;
}
.setup-container .point-addresses-list div:hover {
	color:#000;
	background-color:#f2f2f3;
	cursor:pointer;
}

.modal.setup-modal-map {
	width:1000px;
	height:700px;
	padding:0;
	overflow:hidden;
}
.modal.setup-modal-map .map-container {
	height:100%;
}
.modal.setup-modal-map .map-icon {
	position:absolute;
	top:15px;
	right:15px;
	width:40px;
	height:40px;
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow:0 0 10px rgba(0,0,0,.1);
	border-radius:50%;
	background:#fff;
	cursor:pointer;
}
.modal.setup-modal-map .map-icon.map-close {
	right:15px;
}
.modal.setup-modal-map .map-icon.map-edit {
	right:65px;
}

.modal.setup-modal-category {
	width:450px;
	display:flex;
	flex-direction:column;
}
.modal.setup-modal-category .container-inner {
	width:100%;
	padding-bottom:20px;
}
.modal.setup-modal-category .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.modal.setup-modal-category .product-edit .container-inner .product-edit-row {
	padding:5px 0;
}
.modal.setup-modal-category .product-edit .container-inner .product-edit-row input {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.modal.setup-modal-category .product-edit .container-inner .product-edit-row input:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.setup-modal-category .product-edit .container-inner .product-edit-row input::placeholder {
	color:#caccce
}
.modal.setup-modal-category .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
}
.modal.setup-modal-category .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.modal.setup-modal-category .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.modal.setup-modal-category .product-edit .product-edit-footer .btn-save {
	background:#000;
	color:#fff;
}

.modal.modal-finish .btn-done {
	color:#000;
	background:#ffc060;
}
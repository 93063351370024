.product-accordeon-accordeon {
    padding:10px 0;
    border-bottom:1px solid #f1f3f4;
}
.product-accordeon-accordeon.product-accordeon-hide .product-accordeon-content {
    display:none;
}
.product-accordeon-accordeon .product-accordeon-title {
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor:pointer;
}
.product-accordeon-accordeon .product-accordeon-title h4 {
    margin:0 0 0 2px !important;
}
.product-accordeon-accordeon .product-accordeon-title .info {
    width:360px;
    margin-left:2px;
    font-size:90%;
    color:#6c757d;
}
.product-accordeon-accordeon .product-accordeon-title .product-accordeon-arrow {
	width:36px;
    height:36px;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
	background:#f1f3f4 url(./Images/arrow-down.svg) no-repeat center center;
    transform:rotate(180deg);
}
.product-accordeon-accordeon.product-accordeon-hide .product-accordeon-arrow {
	transform:rotate(0);
}

.product-accordeon-accordeon .product-view-row {
    padding-right:13px !important;
    padding-left:3px !important;
}
.product-accordeon-accordeon .product-view-row:last-child {
    border-bottom:none !important;
}
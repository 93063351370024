.modal.modal-map {
	width:1000px;
	height:700px;
	padding:0;
	overflow:hidden;
}
.modal.modal-map .map-container {
	height:100%;
}
.modal.modal-map .map-icon {
	position:absolute;
	top:15px;
	right:15px;
	width:40px;
	height:40px;
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow:0 0 10px rgba(0,0,0,.1);
	border-radius:50%;
	background:#fff;
	cursor:pointer;
}
.modal.modal-map .map-icon.map-close {
	right:15px;
}
.modal.modal-map .map-icon.map-edit {
	right:65px;
}
.components-container {
	display:flex;
	flex-direction:row;
	padding:20px;
	height:100%;
}

.components-container .categories-container {
	display:flex;
	flex-direction:row;
	width:65%;
	border-right:1px solid #f2f2f3;
}
.components-container .categories-container.checklist-container {
	width:100%;
	border-right:none;
}
.components-container .categories-container.checklist-container .products-list {
	flex:1;
}
.components-container .categories-container.checklist-container .products-list .container-inner {
	height:100%;
	margin-top:20px;
	padding:10px;
	padding-top:0;
	padding-left:0;
	padding-right:0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}

.components-container .categories-container.checklist-container .product-view-list-check {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	padding:12px 4px 10px 8px;
	border-bottom:1px solid #f2f2f3;
}
.components-container .categories-container.checklist-container .product-view-list-check:hover {
	background:#f8f8f8;
}
.components-container .categories-container.checklist-container .product-view-list-check:first-child {
	padding-top:16px;
	border-top:1px solid #f2f2f3;
}
.components-container .categories-container.checklist-container .product-view-list-check b {
	display:block;
}
.components-container .categories-container.checklist-container .product-view-list-check img {
	width:20px;
	margin:2px 10px 0 0;
}
.components-container .categories-container.checklist-container .product-view-list-check span {
	display:block;
	font-size:90%;
	color:#666;
}
.components-container .categories-container.checklist-container .product-view-list-check .btn {
	margin:2px 0 0 auto;
	padding:5px 8px 4px;
	font-size:85%;
	color:#4a4d4f;
	border-radius:24px;
	background:transparent;
}
.components-container .categories-container.checklist-container .product-view-list-check .btn:hover {
	cursor:pointer;
	background:#eee;
}
.components-container .categories-container.checklist-container .product-view-list-check .btn.btn-done {
	display:flex;
	flex-direction:row;
	align-items:center;
	color:#4caf50;
}
.components-container .categories-container.checklist-container .product-view-list-check .btn.btn-done img {
	width:18px;
	margin:0 6px 0 0;
}
.components-container .categories-container.checklist-container .product-view-list-check .btn.btn-done:hover {
	cursor:default;
	background:transparent;
}
.components-container .categories-container.checklist-container .product-view-list-check .link a {
	font-size:95%;
	color:#2791fb;
}
.error-container {
	width:100%;
	height:100%;
	padding-top:100px;
	overflow:hidden;
}
.error-container p {
	margin:20px 0;
	font-size:110%;
	text-align:center;
}
.error-container h1 {
	text-align:center;
	margin-bottom:50px;
}
.error-container h1 span  {
	font-size:50%;
	font-weight:normal;
	display:block;
	color:#555;
}
.error-container a.btn {
	display:flex;
	align-items:center;
	justify-content:center;
	width:160px;
	padding:10px 0;
	margin:40px auto 0;
	font-size:110%;
	color:#fff;
	border-radius:6px;
	border:none;
    background:#122034;
	text-decoration:none;
	transition:.3s;
}
.error-container a.btn:hover {
	cursor:pointer;
	opacity:.8;
}
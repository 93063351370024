.tags-icon-container {
	margin:10px 0 0;
}
.tags-icon-container .icons {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:justify-content;
	flex-wrap:wrap;
}
.tags-icon-container .icons .icon {
	display:flex;
	align-items:center;
	justify-content:center;
	width:50px;
	height:50px;
	margin:0 10px 5px 0;
	padding:5px;
	background:#f2f2f2;
	border-radius:50%;
	border:2px solid #f2f2f2;
	cursor:pointer;
}
.tags-icon-container .icons .icon img {
	width:16px;
	height:16px;
}
.tags-icon-container .icons .icon.select {
	border-color:#ffc060;
}

.tag-icon {
	display:inline-flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	margin-top:10px;
	padding:6px 10px;
	border-radius:16px;
}
.tag-icon img {
	width:16px;
	height:16px;
}
.tag-icon span {
	margin:0 5px;
	font-weight:bold;
}
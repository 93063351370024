.reports-container {
	display:flex;
	flex-direction:column;
	height:100%;
}

.reports-container .controls {
	position:relative;
	display:flex;
	align-items:center;
	padding:10px;
	border-radius:12px;
	background:#f2f2f3;
}
.reports-container .tabs {
	display:flex;
	align-items:center;
}
.reports-container .tabs .tab {
	padding:9px 10px;
	border-right:1px solid #eee;
	background:#fff;
	cursor:pointer;
}
.reports-container .tabs .tab:first-child {
	padding-left:15px;
	border-radius:8px 0 0 8px;
}
.reports-container .tabs .tab:last-child {
	padding-right:15px;
	border-radius:0 8px 8px 0;
	border:none;
}
.reports-container .tabs .tab.tab-active {
	background:#007bff30;
	border-right-color:#007bff30;
}
.reports-container .dates {
	display:flex;
	align-items:center;
	margin-left:25px;
}
.reports-container .dates input {
	width:130px;
	margin:0 8px;
	padding:10px 10px 11px;
	border-radius:8px;
	border:none;
	background:#fff;
}
.reports-container .dates input.active {
	background:#007bff30;
}
.reports-container .controls .button {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-left:auto;
	border-radius:8px;
	background:#ffc060;
	cursor:pointer;
}
.reports-container .controls .button:hover {
	opacity:.9;
	transition:all .3s;
}
.reports-container .controls .button .btn-action {
	padding:9px 15px 9px 20px;
}
.reports-container .controls .button .btn-arrowdown {
	width:40px;
	height:26px;
	margin-right:3px;
	border-left:1px solid rgba(0,0,0,.3);
	background:url('./Images/arrow-down.svg') no-repeat center center;
}
.reports-container .controls .button .btn-arrowdown.btn-arrowdown-open {
	border-left:none;
	border-right:1px solid rgba(0,0,0,.3);
	transform:rotate(180deg);
}
.reports-container .controls .filter {
	position:absolute;
	top:64px;
	right:6px;
	width:300px;
	padding:14px;
	font-size:100%;
	border-radius:12px;
	background:#fff;
	box-shadow:0 4px 32px 0 #1d1d1d1f;
	z-index:101;
}
.reports-container .controls .filter:before {
	content:'';
	position:absolute;
	top:-10px;
	left:265px;
	width:0;
	height:0;
	border-left:10px solid transparent;
	border-right:10px solid transparent;
	border-bottom:10px solid #fff;
}
.reports-container .controls .filter h4 {
	margin-bottom:10px;
	color:#4a4d4f;
}
.reports-container .controls .filter .close {
	position:absolute;
	top:10px;
	right:15px;
	cursor:pointer;
}
.reports-container .controls .filter .filter-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-bottom:10px;
}
.reports-container .controls .filter .filter-row label {
	color:#4a4d4f;
}
.reports-container .controls .filter .select {
	min-width:120px;
	padding:8px 12px;
	border-radius:8px;
	background:#f2f2f3;
}
.reports-container .controls .filter select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.reports-container .controls .filter .filter-row.filter-row-column {
	display:flex;
	flex-direction:column;
	align-items:flex-start;
}
.reports-container .controls .filter .filter-row.filter-row-column label {
	margin-bottom:5px;
}
.reports-container .controls .filter .filter-row.filter-row-column .select {
	width:100%;
}
.reports-container .controls .filter .inputs {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.reports-container .controls .filter .inputs input {
	width:70px;
	padding:11px 12px;
	border-radius:8px;
	border:none;
	background:#f2f2f3;
}
.reports-container .controls .filter .inputs input:last-child {
	margin-left:10px;
}
.reports-container .controls .filter .buttons {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-top:20px;
	padding-top:10px;
	border-top:1px solid #f2f2f3;
}
.reports-container .controls .filter .buttons button {
	width:49%;
	padding:12px 20px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#fff;
	font-size:100%;
	background:#1e1f20;
}
.reports-container .controls .filter .buttons button.btn-second {
	color:#1e1f20;
	background:#f2f2f3;
}

.reports-container .main {
	height:calc(100% - 70px);
	width:100%;
	margin-top:10px;
    padding:0 0 10px 0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.reports-container .main .empty {
	display:flex;
	height:100%;
}
.reports-container .main .stat-oneline {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:flex-start;
}
.reports-container .main .stat-oneline-wide {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.reports-container .main .stat-oneline-up {
	align-items:flex-start;
}
.reports-container .main .stat-oneline-bottom {
	align-items:flex-end;
}
.reports-container .main .empty-inline {
	margin:20px 0 0 10px;
}
.reports-container .main table td {
	cursor:default;
}
.reports-container .main table.items-clients td {
	cursor:pointer
}
.reports-container .main .chart {
	width:100%;
	height:340px;
	margin:10px 0 30px;
}
.reports-container .main table tr.section {
	background:#eee;
}
.reports-container .main table tr.section td {
	padding-left:10px !important;
	font-weight:bold;
}
.reports-container .main table tr.section:hover {
	background:#eee !important;
}
.reports-container .main table tr.section-lite,
.reports-container .main table tr.section-lite:hover {
	background:none !important;
}
.reports-container .main table tr.section-lite td {
	padding-left:5px !important;
}
.reports-container .main table tr th.quantity,
.reports-container .main table tr td.quantity {
	width:100px;
	font-size:90%;
	text-align:center !important;
	color:#666;
}
.reports-container .main table tr td.category {
	color:#666;
	font-size:90%;
}
.reports-container .main table tr td.orderstatus {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.reports-container .main table tr td.orderstatus .orderstatus-point {
	width:12px;
	height:12px;
	margin:0 15px 0 5px;
	border-radius:50%;
}
.reports-container .main table tr.empty-row {
	height:30px;
}
.reports-container .main table tr.empty-row:hover {
	background:none !important;
}
.reports-container .main table tr th.cellABC,
.reports-container .main table tr td.cellABC {
	width:120px;
	padding-right:10px !important;
	text-align:right !important;
}
.reports-container .main table tr td.cellABC div {
	padding-right:15px !important;
}
.reports-container .main table tr td.cellABC.cellA {
	background:#c5efcd;
}
.reports-container .main table tr td.cellABC.cellB {
	background:#ffec9c;
}
.reports-container .main table tr td.cellABC.cellC {
	background:#ffc6cd;
}
.reports-container .main table tr:hover table.inner-table thead th {
	background:#ddd !important;
}
.reports-container .main table tr:hover table.inner-table tbody td {
	background:#fff;
}
.reports-container .main table td table.inner-table {
	width:60%;
}
.reports-container .main table td table.inner-table thead {
	position:static !important;
	box-shadow:none !important;
	background:#f2f2f3;
}
.reports-container .main table td table.inner-table thead tr {
	background:#eee !important;
}
.reports-container .main table td table.inner-table thead th {
	padding:2px 5px !important;
	color:#666 !important;
}
.reports-container .main table table.inner-table tr td {
	padding:2px 5px !important;
	font-size:90% !important;
}
.reports-container .main table table.inner-table tr:last-child td {
	border:none;
}

.modal.modal-orders {
	width:900px;
	height:600px;
}
.modal.modal-orders .container-inner {
	height:calc(100% - 40px);
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
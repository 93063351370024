.layout-container {
	display:flex;
	flex-direction:row;
	padding:20px;
	height:100%;
}

.layout-container .categories-container {
	display:flex;
	flex-direction:row;
	width:65%;
	border-right:1px solid #f2f2f3;
}
.layout-container .categories-container.categories-container-wide {
	width:100%;
	border-right:none;
}

.layout-container .categories-container .products-list {
	flex:1;
}
.layout-container .categories-container .products-list .products-list-controls {
	position:relative;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.layout-container .categories-container .products-list .products-list-controls input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.layout-container .categories-container .products-list .products-list-controls input.search.search-single {
	width:calc(100% - 10px);
}
.layout-container .categories-container .products-list .products-list-controls input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.layout-container .categories-container .products-list .products-list-controls button {
	position:relative;
	top:1px;
	padding:0 5px;	
	border:none;
	background:none;
	cursor:pointer;
}
.layout-container .categories-container.categories-container-wide .products-list .products-list-controls button {
	padding:0 0 0 5px;
}
.layout-container .categories-container .products-list .products-list-controls button img {
	display:block;
	width:47px;
	height:47px;
}
.layout-container .categories-container .products-list .products-list-controls button.filter-button {
	position:relative;
}
.layout-container .categories-container .products-list .products-list-controls button.filter-button span.count {
	position:absolute;
	top:-2px;
	right:-2px;
	width:18px;
	height:18px;
	border-radius:50%;
	background:#ffc060;
	color:#fff;
	font-size:70%;
	font-weight:bold;
	line-height:19px;
	text-align:center;
}
.layout-container .categories-container .products-list .products-list-controls .filter {
	position:absolute;
	top:62px;
	right:50px;
	width:300px;
	padding:14px;
	font-size:100%;
	border-radius:12px;
	background:#fff;
	box-shadow:0 4px 32px 0 #1d1d1d1f;
	z-index:101;
}
.layout-container .categories-container .products-list .products-list-controls .filter:before {
	content:'';
	position:absolute;
	top:-10px;
	left:265px;
	width:0;
	height:0;
	border-left:10px solid transparent;
	border-right:10px solid transparent;
	border-bottom:10px solid #fff;
}
.layout-container .categories-container .products-list .products-list-controls .filter h4 {
	margin-bottom:10px;
	color:#4a4d4f;
}
.layout-container .categories-container .products-list .products-list-controls .filter .close {
	position:absolute;
	top:10px;
	right:10px;
	cursor:pointer;
}
.layout-container .categories-container .products-list .products-list-controls .filter .filter-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-bottom:10px;
}
.layout-container .categories-container .products-list .products-list-controls .filter .filter-row label {
	color:#4a4d4f;
}

.layout-container .categories-container .products-list .products-list-controls .filter .buttons {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-top:20px;
	padding-top:10px;
	border-top:1px solid #f2f2f3;
}
.layout-container .categories-container .products-list .products-list-controls .filter .buttons button {
	width:49%;
	padding:12px 20px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#fff;
	font-size:100%;
	background:#1e1f20;
}
.layout-container .categories-container .products-list .products-list-controls .filter .buttons button.btn-second {
	color:#1e1f20;
	background:#f2f2f3;
}
.layout-container .categories-container .products-list .products-list-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	height:58px;
	margin-right:10px;
	padding:12px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.layout-container .categories-container.categories-container-wide .products-list .products-list-footer {
	margin-right:0;
}
.layout-container .categories-container .products-list .products-list-footer .footer-selects {
	width:100%;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.layout-container .categories-container .products-list .products-list-footer .footer-selects .select {
	width:100%;
	margin-right:10px;
	padding:11px 15px 10px;
	border-radius:12px;
	border:1px solid #ccc;
	background:#fafafa;
}
.layout-container .categories-container .products-list .products-list-footer .footer-selects .select:hover {
	border-color:#b0b3b5;
	background:#fff;
}
.layout-container .categories-container .products-list .products-list-footer .footer-selects .select select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.layout-container .categories-container .products-list .products-list-footer .button {
	padding:12px 15px 11px;
	color:#fff;
	white-space:nowrap;
	border-radius:12px;
	background:#1e1f20;
	cursor:pointer;
}
.layout-container .categories-container .products-list .products-list-footer .button:hover {
	background:#000;
}
.layout-container .categories-container .products-list .products-list-footer .button-disable,
.layout-container .categories-container .products-list .products-list-footer .button-disable:hover {
	background:#1e1f20;
	opacity:.5;
	cursor:not-allowed;
}
.layout-container .categories-container .products-list .container-inner {
	height:calc(100% - 70px);
	margin-top:20px;
	padding:10px;
	padding-top:0;
	padding-left:0;
	padding-right:0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.layout-container .categories-container .products-list .container-inner.container-inner-with-footer {
	height:calc(100% - 124px);
}
.layout-container .categories-container .products-list .container-inner.container-inner-without-addsearch {
	height:calc(100% - 20px);
}
.layout-container .categories-container .products-list .products-empty {
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	height:calc(100% - 60px);
}
.layout-container .categories-container .products-list .products-empty div {
	margin-top:260px;
	text-align:center;
	color:#4a4d4f;
}
.layout-container .categories-container .products-list .products-empty div span {
	display:block;
	margin-top:10px;
	font-size:90%;
	color:#6a6d6f;
}
.layout-container .categories-container .products-list .products-empty img {
	position:absolute;
	top:20px;
	right:30px;
}
.layout-container .categories-container .products-list table.items {
	width:100%;
	border-collapse:collapse;
}
.layout-container .categories-container .products-list table.items thead {
	position:sticky;
	top:0;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	z-index:100;
}
.layout-container .categories-container .products-list table.items thead tr th,
.layout-container .categories-container .products-list table.items tr td {
	padding:10px 0 5px 5px;
	text-align:left;
}
.layout-container .categories-container .products-list table.items tr td {
	border-bottom:1px solid #f2f2f3;
}
.layout-container .categories-container .products-list table.items tbody tr:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.layout-container .categories-container .products-list table.items thead tr th {
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
	cursor:pointer;
}
.layout-container .categories-container .products-list table.items th.control {
	cursor:default;
}
.layout-container .categories-container .products-list table.items.items-no-control th:last-child,
.layout-container .categories-container .products-list table.items.items-no-control td:last-child {
	padding-right:10px;
}
.layout-container .categories-container .products-list table.items thead tr th.sort span {
	display:inline-block;
	width:8px;
	height:8px;
	margin-left:4px;
}
.layout-container .categories-container .products-list table.items thead tr th.sort.sort-both span {
	height:10px;
	background:url('./Images/sort-both.svg') no-repeat center center;
}
.layout-container .categories-container .products-list table.items thead tr th.sort.sort-up span {
	background:url('./Images/sort-up.svg') no-repeat center center;
}
.layout-container .categories-container .products-list table.items thead tr th.sort.sort-down span {
	background:url('./Images/sort-down.svg') no-repeat center center;
}
.layout-container .categories-container .products-list table.items tbody tr.row-selected {
	background:#e5e6e6;
}
.layout-container .categories-container .products-list table.items tr td span {
	display:block;
	font-size:80%;
	color:#aaa;
}
.layout-container .categories-container .products-list table.items tr td div.hide {
	color:#aaa;
}
.layout-container .categories-container .products-list table.items tr td div.hide::after {
	content:'';
	display:inline-block;
	width:16px;
	height:16px;
	margin-left:10px;
	background-image:url('./Images/eye-off-grey.svg');
	background-repeat:no-repeat;
	background-position:center 3px;
	background-size:16px;
}
.layout-container .categories-container .products-list table.items .area {
	width:200px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items td.area {
	color:#6a6d6f;
	font-size:80%;
}
.layout-container .categories-container .products-list table.items .weight {
	width:100px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items td.weight {
	color:#6a6d6f;
}
.layout-container .categories-container .products-list table.items .avatar {
	width:64px;
}
.layout-container .categories-container .products-list table.items .avatar img {
	width:48px;
	height:48px;
	border-radius:10px;
	object-fit:cover;
}
.layout-container .categories-container .products-list table.items .icon {
	width:30px;
}
.layout-container .categories-container .products-list table.items .icon img {
	width:16px;
	height:16px;
}
.layout-container .categories-container .products-list table.items .amount {
	text-align:right;
}
.layout-container .categories-container .products-list table.items .amount-wide {
	width:100px;
}
.layout-container .categories-container .products-list table.items td.amount {
	color:#6a6d6f;
}
.layout-container .categories-container .products-list table.items td.amount b {
	color:#000;
}
.layout-container .categories-container .products-list table.items .prices {
	width:70px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items td.prices {
	color:#6a6d6f;
}
.layout-container .categories-container .products-list table.items .prices-mid {
	width:100px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items .prices-wide {
	width:120px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items .discount {
	width:70px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items td.discount {
	color:#6a6d6f;
}
.layout-container .categories-container .products-list table.items .money {
	width:70px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items .attach {
	width:80px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items td.attach {
	color:#4a4d4f;
}
.layout-container .categories-container .products-list table.items .status-last {
	padding-right:10px;
}
.layout-container .categories-container .products-list table.items .date {
	width:100px;
	text-align:right;
	padding-right:10px;
}
.layout-container .categories-container .products-list table.items .datetime {
	width:140px;
	text-align:right;
	padding-right:10px;
}
.layout-container .categories-container .products-list table.items .datetime.datetime-start {
	text-align:left;
}
.layout-container .categories-container .products-list table.items td.workshop {
	font-size:90%;
	color:#4a4d4f;
}
.layout-container .categories-container .products-list table.items td.workshop i {
	font-size:100%;
}
.layout-container .categories-container .products-list table.items .type {
	width:90px;
}
.layout-container .categories-container .products-list table.items .type .type0 {
	color:#f44336;
}
.layout-container .categories-container .products-list table.items .type .type1 {
	color:#4ad992;
}
.layout-container .categories-container .products-list table.items .status {
	width:70px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items .status.status-small {
	width:60px;
}
.layout-container .categories-container .products-list table.items .status .status-point {
	width:8px;
	height:8px;
	margin-right:8px;
	border-radius:50%;
}
.layout-container .categories-container .products-list table.items .status0,
.layout-container .categories-container .products-list table.items .status1 {
	display:flex;
	flex-direction:row;
	align-items:baseline;
	justify-content:flex-end;
    color:#636669;
    font-size:90%;
}
.layout-container .categories-container .products-list table.items .status .status0 .status-point {
	background:#ffc060;
}
.layout-container .categories-container .products-list table.items .status .status1 .status-point {
	background:#4ad992;
}
.layout-container .categories-container .products-list table.items .current {
	width:70px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items .current-wide {
	width:100px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items .color {
	width:100px;
	text-align:right;
}
.layout-container .categories-container .products-list table.items .color .color-example {
	margin-left:auto;
	width:40px;
	height:10px;
	border-radius:4px;
}
.layout-container .categories-container .products-list table.items .options {
	margin-top:2px;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.layout-container .categories-container .products-list table.items .options span {
	display:block;
	font-size:70%;
	color:#333;
	margin-right:10px;
	padding:1px 5px 0 5px;
	border-radius:20px;
	background:#eaeaea;
}
.layout-container .categories-container .products-list table.items .options span.new {
	color:#fff;
	background:#4caf50;
}
.layout-container .categories-container .products-list table.items .options span.stop {
	color:#fff;
	background:#f44336;
}
.layout-container .categories-container .products-list table.items .options span.top {
	color:#fff;
	background:#4a4d4f;
}
.layout-container .categories-container .products-list table.items .control {
	position:relative;
	width:60px;
}
.layout-container .categories-container .products-list table.items .control .control-inner {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:flex-end;
	margin-left:auto;
}
.layout-container .categories-container .products-list table.items .control img.check,
.layout-container .categories-container .products-list table.items .control .control-inner img.check {
	display:block;
	width:18px;
	height:18px;
	margin:0 auto 0 10px;
	cursor:default;
}
.layout-container .categories-container .products-list table.items .control img {
	display:block;
	margin-left:auto;
	cursor:pointer;
}
.layout-container .categories-container .products-list table.items .control .control-block {
	position:absolute;
	top:-100000px;
	right:5px;
	border-radius:8px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	padding:10px 0;
	color:#4a4d4f;
	z-index:100;
	white-space:nowrap;
}
.layout-container .categories-container .products-list table.items .info-row,
.layout-container .categories-container .products-list table.items .info-row:hover {
	background: #fafafa !important;
	cursor:default !important;
}
.layout-container .categories-container .products-list table.items .info-row td {
	font-weight: bold;
}
.layout-container .categories-container .products-list table.items .control .control-block div {
	padding:10px 50px 10px 20px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.edit {
	background:url('./Images/edit.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.copy {
	background:url('./Images/copy.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.price {
	background:url('./Images/money.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.delete {
	background:url('./Images/delete-grey.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.active {
	background:url('./Images/eye-on-grey.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.inactive {
	background:url('./Images/eye-off-grey.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.stopliston {
	background:url('./Images/check.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.stoplistoff {
	background:url('./Images/donotenter.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.receipt {
	background:url('./Images/receipt.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.check {
	background:url('./Images/check.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.uncheck {
	background:url('./Images/uncheck.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.profile {
	background:url('./Images/user.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div.settings {
	background:url('./Images/settings.svg') no-repeat center right 10px;
}
.layout-container .categories-container .products-list table.items .control .control-block div:hover {
	color:#000;
	background-color:#f2f2f3;
}
.layout-container .categories-container .products-list table.items .control .control-block div.empty {
	cursor:default;
}
.layout-container .categories-container .products-list table.items .control .control-block div.empty:hover {
	background-color:transparent
}

.layout-container .product-edit {
	display:flex;
	width:35%;
}
.layout-container .product-edit .product-add-container {
	margin-top:10px;
}
.layout-container .product-edit .product-add-container p {
	margin-bottom:10px;
	color:#4a4d4f;
}
.layout-container .product-edit .product-add-container button {
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}
.layout-container .product-edit .product-edit-list-container {
	display:flex;
	flex-direction:column;
	width:100%;
	margin-left:10px;
}
.layout-container .product-edit .container-inner {
	width:100%;
	height:100%;
	padding-right:15px;
	overflow-y:auto;
}
.layout-container .product-edit .container-inner .list {
	height:100%;
	padding-bottom:20px;
}
.layout-container .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.layout-container .product-edit .container-inner .product-edit-row {
	padding:10px 0;
}
.layout-container .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.layout-container .product-edit .container-inner h4 .product-edit-row-switcher {
	margin-right:5px;
	font-size:85%;
	font-weight:normal;
}
.layout-container .product-edit .container-inner h4 .product-edit-row-switcher img {
	margin-right:8px;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-view-row {
	padding:10px 0;
	border-bottom:1px solid #f2f2f3;
}
.layout-container .product-edit .container-inner .product-view-row-simple {
	padding:10px 0;
	border:none;
}
.layout-container .product-edit .container-inner .product-view-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.layout-container .product-edit .container-inner .product-view-row-oneline-start {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:flex-start;
}
.layout-container .product-edit .container-inner .product-view-row-oneline-up {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:space-between;
}
.layout-container .product-edit .container-inner .product-view-row .avatar {
	width:100px;
	height:100px;
	margin-right:20px;
	border-radius:16px;
	object-fit:cover;
}
.layout-container .product-edit .container-inner .product-view-row h3 {
	margin-top:10px;
}
.layout-container .product-edit .container-inner .product-view-row span.user-type {
	display:block;
	font-size:90%;
	color:#4a4d4f;
	margin-top:5px;
}
.layout-container .product-edit .container-inner .product-view-row span {
	font-size:100%;
	color:#4a4d4f;
}
.layout-container .product-edit .container-inner .product-view-row img.edit {
	margin-top:5px;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-view-row .comment,
.layout-container .product-edit .container-inner .product-view-row .description {
	color:#4a4d4f;
}
.layout-container .product-edit .container-inner .product-view-row.product-view-row-alert {
	margin:10px 0;
	padding:10px;
	font-size:90%;
	color:#f44336;
	border-radius:8px;
	border:1px solid #f44336;
	background:#ffebee;
}
.layout-container .product-edit .container-inner .product-edit-row-select {
	margin:5px 0;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.layout-container .product-edit .container-inner .product-edit-row-plus img.categoty-add {
	height:51px;
	margin-left:8px;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-row label {
	width:150px;
	color:#4a4d4f;
}
.layout-container .product-edit .container-inner .product-edit-row label.label-middle {
	width:280px;
}
.layout-container .product-edit .container-inner .product-edit-row label.label-middle span {
	display:block;
	font-size:80%;
	color:#999;
}
.layout-container .product-edit .container-inner .product-edit-row input,
.layout-container .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.layout-container .product-edit .container-inner .product-edit-row input:focus,
.layout-container .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.layout-container .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.layout-container .product-edit .container-inner .product-edit-row textarea.lite {
	height:80px;
}
.layout-container .product-edit .container-inner .product-edit-row input::placeholder,
.layout-container .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.layout-container .product-edit .container-inner .product-edit-row .input-title,
.layout-container .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.layout-container .product-edit .container-inner .product-edit-row input.color,
.layout-container .product-edit .container-inner .product-edit-row input.color:focus {
	width:100%;
	margin:0;
	padding:0;
	border:none;
}
.layout-container .product-edit .container-inner .product-edit-row input.number {
	text-align:right;
}
.layout-container .product-edit .container-inner .product-edit-row input.time {
	width:49%;
}
.layout-container .product-edit .container-inner .product-edit-row .input {
	width:100%;
	padding:9px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
}
.layout-container .product-edit .container-inner .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.layout-container .product-edit .container-inner .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.layout-container .product-edit .container-inner .product-edit-row .select.select-middle {
	width:200px;
}
.layout-container .product-edit .container-inner .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.layout-container .product-edit .container-inner .product-edit-row input[type="file"] {
	display:none;
}
.layout-container .product-edit .container-inner .product-edit-row-oneline-start {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:flex-start;
}
.layout-container .product-edit .container-inner .product-edit-row-simple {
	padding:3px 0;
	border-bottom:1px solid #f2f2f3;
}
.layout-container .product-edit .container-inner .product-edit-row-simple-noline {
	border:none;
}
.layout-container .product-edit .container-inner .product-edit-row-simple img {
	margin-right:10px;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-area {
	padding-bottom:10px;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-area:last-child {
	padding-bottom:0;
}
.layout-container .product-edit .container-inner .product-edit-row .checkbox {
	margin-right:10px;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-row .info {
	display:block;
	color:#6C757B;
	font-size:90%;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-btn {
	display:inline-block;
	margin-right:10px;
	padding:8px 24px;
	border:none;
	border-radius:8px;
	color:#1e1f20;
	font-size:100%;
	background:#eee;
	cursor:pointer;
	text-decoration:none;
}
.layout-container .product-edit .container-inner .product-edit-row button.product-edit-btn {
	padding:11px 24px;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-btn:hover {
	background:#ccc;
	transition:all 1s;
} 
.layout-container .product-edit .container-inner .product-edit-row .product-edit-btn-second {
	display:inline-block;
	padding:8px 24px;
	color:#000 !important;
	background:#c3c3d3 !important;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-btn-second:hover {
	background:#9a9ab3 !important;
	transition:all 1s;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-images {
	display:flex;
	flex-direction:row;
	align-items:center;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image {
	position:relative;
	width:88px;
	height:88px;
	margin-right:10px;
	margin-bottom:10px;
	border-radius:12px;
	overflow:hidden;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image .delete {
	position:absolute;
	top:calc(50% - 38px / 2);
	right:calc(50% - 38px / 2);
	width:38px;
	height:38px;
	background:url('./Images/delete-black-btn.svg') no-repeat 50% 50%;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image-main {
	border:2px solid #2f8cff;
}
.layout-container .product-edit-images-wide {
	margin-bottom:10px;
}
.layout-container .product-edit-images-wide .product-edit-image {
	position:relative;
}
.layout-container .product-edit-images-wide img.image {
	width:100%;
	height:150px;
	object-fit:contain;
}
.layout-container .product-edit-images-wide img.image-small {
	width:100px;
	height:100px;
	object-fit:contain;
}
.layout-container .product-edit-images-wide img.image.image-error {
	object-fit:cover;
}
.layout-container .product-edit-images-wide .product-edit-image .delete {
	position:absolute;
	top:calc(50% - 38px / 2);
	right:calc(50% - 38px / 2);
	width:38px;
	height:38px;
	background:url('./Images/delete-black-btn.svg') no-repeat 50% 50%;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-components {
	display:flex;
	flex-direction:row;
	align-items:center;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component {
	display:flex;
	flex-direction:row;
	align-items:flex-end;
	margin:0 10px 10px 0;
	padding:5px;
	padding-left:10px;
	font-size:90%;
	border-radius:30px;
	border:1px solid #caccce;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component:hover {
	border-color:#999;
}
.layout-container img.close-icon {
	width:20px;
	height:20px;
	margin-left:5px;
	margin-right:6px;
	padding:3px;
	background:#f2f2f2;
	border-radius:50%;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component img:hover {
	background:#e5e6e6;
}
.layout-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component img.close-icon {
	margin:0 0 0 5px;
}
.layout-container .product-edit .container-inner .product-edit-components-block {
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item {
	padding:5px 2px;
	border-bottom:1px solid #f2f2f3;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item input {
	display:block;
	margin:5px 0;
	padding:8px;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .product-edit-component-item-name {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .product-edit-component-item-name img {
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .product-edit-component-item-name span {
	color:#666;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .product-edit-component-item-name b span {
	font-weight:normal;
	color:#4a4d4f;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .product-edit-component-item-name span.type {
	margin-left:10px;
	color:#4a4d4f;
	font-weight:normal;
	font-size:90%;
	border-radius:10px;
	padding:2px 6px;
	background:#e5e6e6;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .product-edit-component-item-name .name {
	font-size:100%;
	font-weight:bold;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .product-edit-component-item-name span.info {
	margin:0;
	font-size:90%;
}
.layout-container .product-edit .container-inner .product-edit-components-block .product-edit-component-item .info {
	color:#666;
	font-size:95%;
}
.layout-container .product-edit .container-inner .product-edit-row .permission-item {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-bottom:10px;
	color:#4a4d4f;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-edit-row .permission-item img {
	margin-right:10px;
}
.layout-container .product-view-row-simple .image {
	width:100%;
	height:150px;
	border-radius:16px;
	object-fit:contain;
}
.layout-container .product-view-row-simple img.image-small {
	width:100px;
	height:100px;
	margin-right:20px;
	border-radius:16px;
	object-fit:cover;
}
.layout-container .product-view-row-simple .image.image-error {
	object-fit:cover;
}
.layout-container .product-view-row-simple .color {
	width:50px;
	height:10px;
	border-radius:5px;
}
.layout-container .product-edit .container-inner .product-edit-row i {
	display:block;
}
.layout-container .product-edit .container-inner .product-view-row-simple .product-view-address {
	width:100%;
	margin-bottom:10px;
	padding:15px;
	border-radius:12px;
	background:#f2f2f3;
}
.layout-container .product-edit .container-inner .product-view-row-simple .product-view-address:hover {
	background:#f2f2f3 url('./Images/edit.svg') no-repeat right 10px center;
	cursor:pointer;
}
.layout-container .product-edit .container-inner .product-view-row-simple .product-view-orders {
	display:flex;
	flex-direction:row;
	align-items:stretch;
	justify-content:space-between;
	flex-wrap:wrap;
}
.layout-container .product-edit .container-inner .product-view-row-simple .product-view-orderinfo {
	width:32%;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	margin-bottom:10px;
	padding:12px;
	border-radius:12px;
	border:1px solid #caccce;
}
.layout-container .product-edit .container-inner .product-view-row-simple .product-view-orderinfo span {
	line-height:1.2;
	color:#4a4d4f;
}
.layout-container .product-edit .container-inner .product-view-row-simple .product-view-orderinfo div {
	margin-top:5px;
	font-weight:bold;
	font-size:120%;
}
.layout-container .product-edit .container-inner .row-notice {
	display:block;
	margin-bottom:20px;
	font-size:90%;
	color:#636669;
	font-style:italic;
}
.layout-container .product-edit .container-inner .product-list {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	padding:5px 10px 5px 0;
}
.layout-container .product-edit .container-inner .product-list span {
	font-size:95%;
	color:#666;
}
.layout-container .product-edit .container-inner .product-list div {
	font-size:95%;
	color:#4a4d4f;
}

.layout-container .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.layout-container .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.layout-container .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.layout-container .product-edit .product-edit-footer .btn-cancel.btn-cancel-wide {
	width:100%;
}
.layout-container .product-edit .product-edit-footer .btn-save {
	background:#ffc060;
}

.sortable {
	display:flex;
	flex-direction:row;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	background:#fff;
	cursor:move;
}
.sortable td {
	display:none;
}
.sortable td:first-child,
.sortable td.no-hide {
	display:flex;
	height:100%;
	padding:0 10px 0 5px;
	align-items: center;
	background:#fff;
}
.sortable td .options {
	display:none;
}
.sortable td:first-child img {
	width:48px;
	height:48px;
	border-radius:10px;
	object-fit:cover;
}

.modal.modal-layout .buttons {
	margin-top:30px;
}
.modal.modal-layout .buttons .btn-delete.btn-delete-disable {
	opacity:.5;
	cursor:not-allowed;
}
.modal.modal-clients {
	width:530px;
	height:620px;
	display:flex;
	flex-direction:column;
}
.modal.modal-clients .btn-accept {
	width:100%;
}
.modal.modal-clients .search-block {
	position:relative;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:10px 0;
}
.modal.modal-clients .search-block .search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.modal.modal-clients input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal-clients .search-block .filter-button {
	position:relative;
	width:46px;
	height:46px;
	margin-left:5px;
	cursor:pointer;
}
.modal.modal-clients .search-block .filter-button img {
	width:46px;
	height:46px;
}
.modal.modal-clients .search-block .filter-button span.count {
	position:absolute;
	top:-2px;
	right:-2px;
	width:18px;
	height:18px;
	border-radius:50%;
	background:#ffc060;
	color:#fff;
	font-size:70%;
	font-weight:bold;
	line-height:19px;
	text-align:center;
}
.modal.modal-clients .search-block .filter {
	position:absolute;
	top:72px;
	right:0;
	width:485px;
	padding:14px;
	font-size:100%;
	border-radius:12px;
	background:#fff;
	box-shadow:0 4px 32px 0 #1d1d1d1f;
	z-index:101;
}
.modal.modal-clients .search-block .filter:before {
	content:'';
	position:absolute;
	top:-10px;
	left:452px;
	width:0;
	height:0;
	border-left:10px solid transparent;
	border-right:10px solid transparent;
	border-bottom:10px solid #fff;
}
.modal.modal-clients .search-block .filter h4 {
	margin-bottom:10px;
	color:#4a4d4f;
}
.modal.modal-clients .search-block .filter .close {
	position:absolute;
	top:10px;
	right:10px;
	cursor:pointer;
}
.modal.modal-clients .search-block .filter .filter-inner {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:space-between;
	margin-bottom:10px;
	border-bottom:1px solid #f2f2f3;
}
.modal.modal-clients .search-block .filter .filter-inner .filter-column {
	display:flex;
	flex-direction:column;
	width:48%;
}
.modal.modal-clients .search-block .filter .filter-inner .filter-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-bottom:10px;
}
.modal.modal-clients .search-block .filter .filter-inner .filter-row label {
	color:#4a4d4f;
}
.modal.modal-clients .search-block .filter .small-input {
	width:80px;
	padding:12px 16px;
	border-radius:12px;
	border:1px solid #b0b3b5;
}
.modal.modal-clients .search-block .filter .buttons {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-top:10px;
	padding-top:10px;
	border-top:1px solid #f2f2f3;
}
.modal.modal-clients .search-block .filter .buttons button {
	width:48%;
	padding:12px 20px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#fff;
	font-size:100%;
	background:#1e1f20;
}
.modal.modal-clients .search-block .filter .buttons button.btn-second {
	color:#1e1f20;
	background:#f2f2f3;
}
.modal.modal-clients .products {
	height:100%;
}
.modal.modal-clients .products .list {
	height:410px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-clients .products .list .product {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:16px 5px 16px 5px;
	border-bottom:1px solid #e5e6e6;
}
.modal.modal-clients .products .list .product div span {
	display:block;
	font-size:90%;
	color:#666;
}
.modal.modal-clients .products .list .product-empty {
	margin-top:40px;
	text-align:center;
}

.modal.modal-clients.modal-clients-lite {
	width:500px;
	height:600px;
}
.modal.modal-clients.modal-clients-lite .products .list {
	height:510px;
}
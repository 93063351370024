.container-empty {
	flex:1;
	display:flex;
	flex-direction:column;
	align-items:center;
	margin:auto;
	text-align:center;
}
.container-empty .container-empty-info {
	text-align:center;
	margin-top:10px;
	color:#4a4d4f;
}
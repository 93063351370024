.calendar {
	display:inline-block;
	margin-top:10px;
	padding:10px;
	border-radius:12px;
	border:1px solid #caccce;
}
.calendar-month {
	margin:0 0 10px 12px;
	font-weight:bold;
}
.calendar-body {
	display:flex;
	flex-direction:column;
	margin-bottom:20px;
}
.calendar-week {
	display:flex;
	flex-direction:row;
	margin-bottom:1px;
}
.calendar-week div.weekday {
	width:36px;
	font-size:90%;
	color:#96999c;;
	text-align:center;
}
.calendar-body .day {
	width:36px;
	height:36px;
	display:flex;
	line-height:0;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	font-size:90%;
	border:1px solid #fff;
	border-radius:8px;
	cursor:pointer;
}
.calendar-body .day:hover {
	background:#f2f2f3;
}
.calendar-body .day.select {
	background:#4ad992 !important;
}
.calendar-body .day.today {
	border:1px solid #96999c;
	background:#f1f3f4;
}
.calendar-body .day.empty {
	background:#fff;
	cursor:default;
}
.calendar-body .day.empty:hover {
	background:#fff;
}
.modal.modal-products {
	width:500px;
	height:600px;
	display:flex;
	flex-direction:column;
}
.modal.modal-products .btn-accept {
	width:100%;
}
.modal.modal-products input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.modal.modal-products input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal-products .products {
	height:100%;
}
.modal.modal-products .products .list {
	height:400px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-products .products .list .product {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:16px 5px 16px 5px;
	border-bottom:1px solid #e5e6e6;
}
.modal.modal-products .products .list .product div span {
	display:block;
	font-size:90%;
	color:#666;
}
.modal.modal-products .products .list .product-empty {
	margin-top:40px;
	text-align:center;
}
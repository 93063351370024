.balances-container {
	width:100%;
	height:100%;
}
.balances-container .controls {
	display:flex;
	align-items:center;
	padding:10px;
	border-radius:12px;
	background:#f2f2f3;
}
.balances-container .controls .select {
	width:220px;
	margin-right:10px;
	padding:9px 15px;
	border-radius:8px;
	background:#fff;
}
.balances-container .controls .select select {
	width:100%;
	border:none;
	margin:0;
	padding:0;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -3px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.balances-container .controls input {
	width:200px;
	margin-right:10px;
	padding:11px 15px;
	border-radius:8px;
	border:none;
	background:#fff;
}
.balances-container .controls button {
	padding:11px 20px;
	font-size:100%;
	border-radius:8px;
	border:none;
	color:#fff;
	background:#000;
	cursor:pointer;
}
.balances-container .items-container {
	height:calc(100% - 70px);
	width:100%;
	margin-top:10px;
    padding:0 0 10px 0;
    overflow-y: auto;
    scrollbar-color:#bbb transparent;
    scrollbar-width:thin;
}
.balances-container .items-container table,
.balances-container .items-container table thead tr th,
.balances-container .items-container table tbody tr td {
	cursor:default !important;
}
.balances-container .items-container table tr.section {
	background:#eee;
}
.balances-container .items-container table tr.section td {
	padding-left:10px !important;
	font-weight:bold;
}
.balances-container .items-container table tr.section:hover {
	background:#eee !important;
}
.balances-container .items-container table tr.section-lite,
.balances-container .items-container table tr.section-lite:hover {
	background:none !important;
}
.balances-container .items-container table tr.section-lite td {
	padding-left:5px !important;
}
.orderadd-container {
	display:flex;
	flex-direction:column;
	height:100vh;
	padding:20px;
}

.orderadd-container .main {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	height:calc(100% - 70px);
	background:#fff;
}
.orderadd-container .main .empty {
	display:flex;
	height:100%;
}
.orderadd-container .main .empty .menu-add-block a,
.orderadd-container .main .empty .client-add-block button {
	display:inline-block;
	margin-top:20px;
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#000;
	font-size:100%;
	background:#ffc060;
	text-decoration:none;
}
.orderadd-container .main .empty .client-add-block button {
	color:#1e1f20;
	background:#f2f2f3;
}
.orderadd-container .main .empty span {
	display:inline-block;
	margin-top:15px;
	color:#6a6d6f;
	font-size:90%;
}
.orderadd-container .main .left-side {
	width:calc(45% - 5px);
	background:#fff;
}
.orderadd-container .main .menu-side {
	width:calc(55% - 5px);
	background:#f2f2f3;
}
.orderadd-container .orderadd-oneline-wide {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.orderadd-container .orderadd-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:unset;
}
.orderadd-container .view-row {
	padding:10px 0;
}
.orderadd-container .edit-row {
	position:relative;
	padding:10px 0;
}
.orderadd-container .margin-top10 {
	margin-top:10px;
}
.orderadd-container .margin-top20 {
	margin-top:20px;
}
.orderadd-container .edit-row input,
.orderadd-container .edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.orderadd-container .edit-row input:focus,
.orderadd-container .edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.orderadd-container .edit-row textarea {
	height:100px;
}
.orderadd-container .edit-row textarea.comment {
	height:80px;
}
.orderadd-container .select {
	width:240px;
	padding:13px 16px;
	border-radius:8px;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
}
.orderadd-container select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.orderadd-container .main .left-side .left-side-tabs {
	margin-bottom:20px;
	padding:4px;
	border-radius:12px;
	background:#f2f2f3;
}
.orderadd-container .main .left-side .left-side-tabs .tab {
	width:30%;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:10px 0;
	border-radius:10px;
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-tabs .tab-active {
	background:#fff;
}
.orderadd-container .main .left-side .left-side-main {
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.orderadd-container .main .left-side .left-side-main.left-side-main-tab0 {
	height:calc(100% - 70px);
	overflow:hidden;
}
.orderadd-container .main .left-side .left-side-main.left-side-main-tab1 {
	height:calc(100% - 215px);
}
.orderadd-container .main .left-side .left-side-main.left-side-main-tab2 {
	height:calc(100% - 242px);
}
.orderadd-container .main .left-side .left-side-main.left-side-main-tab0 .empty {
	height:calc(100% - 70px);
}
.orderadd-container .main .left-side .left-side-main.left-side-main-tab1 .empty {
	height:calc(100% - 100px);
}
.orderadd-container .main .left-side .left-side-main .search-block {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.orderadd-container .main .left-side .left-side-main .search-block input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.orderadd-container .main .left-side .left-side-main .search-block input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.orderadd-container .main .left-side .left-side-main .search-block button {
	position:relative;
	top:1px;
	border:none;
	background:none;
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-main .search-block button img {
	width:47px;
	height:47px;
}
.orderadd-container .main .left-side .left-side-main .clients-lists {
	height:calc(100% - 70px);
	margin-top:20px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.orderadd-container .main .left-side .left-side-main .clients-lists .client {
	padding:10px 15px;
	border-bottom:1px solid #f2f2f3;
}
.orderadd-container .main .left-side .left-side-main .clients-lists .client:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-main .client-info {
	height:calc(100% - 60px);
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.orderadd-container .main .left-side .left-side-main .client-info .client-info-bottom {
	margin-bottom:10px;
}
.orderadd-container .main .left-side .left-side-main .client-info h4 {
	margin:0 0 0 2px;
}
.orderadd-container .main .left-side .left-side-main .client-info .edit  {
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-main .client-info .pickup,
.orderadd-container .main .left-side .left-side-main .client-info .pickup-on {
	padding:5px 10px 5px 5px;
	color:#6a6d6f;
	border-radius:6px;
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-main .client-info .pickup-on {
	color:#1e1f20;
	background:#f2f2f3;
}
.orderadd-container .main .left-side .left-side-main .client-info .pickup img,
.orderadd-container .main .left-side .left-side-main .client-info .pickup-on img {
	width:20px;
	height:20px;
	margin-right:5px;
}
.orderadd-container .main .left-side .left-side-main .pickup-area {
	margin-top:10px;
	padding:5px 10px 10px 5px;
	color:#1e1f20;
	cursor:pointer;
	border-bottom:1px solid #f2f2f3;
}
.orderadd-container .main .left-side .left-side-main .pickup-area:hover {
	background:#f2f2f3;
}
.orderadd-container .main .left-side .left-side-main .pickup-area img {
	width:20px;
	height:20px;
	margin-right:10px;
}
.orderadd-container .main .left-side .left-side-main .pickup-area span {
	display:inline-block;
	margin:5px 0 0 30px;
	color:#6a6d6f;
	font-size:90%;
}
.orderadd-container .main .left-side .left-side-main .client-info .edit-row input.small {
	margin-right:10px;
}
.orderadd-container .main .left-side .left-side-main .client-info .edit-row input.small:last-child {
	margin-right:0;
}
.orderadd-container .main .left-side .footer-controls {
	padding-top:10px;
	border-top:1px solid #f2f2f3;
}
.orderadd-container .main .left-side .footer-controls button {
	width:49%;
	margin-right:10px;
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#fff;
	font-size:100%;
	background:#000;
}
.orderadd-container .main .left-side .footer-controls button.btn-second {
	color:#1e1f20;
	background:#f2f2f3;
}
.orderadd-container .main .left-side .footer-controls button.btn-disable {
	opacity:.5;
	cursor:default;
}
.orderadd-container .main .left-side .left-side-main .client-info .order-view-address {
	width:100%;
	margin-bottom:10px;
	padding:12px;
	color:#6a6d6f;
	border:2px solid transparent;
	border-radius:12px;
	background:#f2f2f3;
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-main .client-info .order-view-address:hover {
	border:2px solid #e5e6e6;
}
.orderadd-container .main .left-side .left-side-main .client-info .order-view-address.order-view-address-select {
	color:#000;
	border:2px solid #ffc060;
	background:#fff;
}
.orderadd-container .main .left-side .left-side-main .client-info .order-view-address img {
	padding-left:15px;
	border-left:1px solid #e5e6e6;
}
.orderadd-container .main .left-side .left-side-main .client-info .view-row label {
	color:#6a6d6f;
}
.orderadd-container .main .left-side .left-side-main .client-info .addresses-list {
	position:absolute;
	top:-5px;
	width:100%;
	max-height:230px;
	padding:10px 0;
	color:#4a4d4f;
	border-radius:12px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	border:1px solid #f2f2f3;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
	z-index:999;
}
.orderadd-container .main .left-side .left-side-main .client-info .addresses-list div {
	padding:10px 50px 10px 20px;
}
.orderadd-container .main .left-side .left-side-main .client-info .addresses-list div:hover {
	color:#000;
	background-color:#f2f2f3;
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-main .client-info .btn-button {
	display:inline-block;
	padding:12px 20px;
	border:none;
	border-radius:12px;
	color:#fff;
	font-size:100%;
	background:#000;
	cursor:pointer;
	text-decoration:none;
}
.orderadd-container .main .left-side .left-side-main .order-info-row {
	padding:10px;
	border-bottom:1px solid #f2f2f3;	
}
.orderadd-container .main .left-side .left-side-main .order-info-row .order-info-counter {
	display:flex;
	flex-direction:row;
	align-items:center;
	width:110px;
}
.orderadd-container .main .left-side .left-side-main .order-info-row .order-info-counter img {
	width:36px;
	cursor:pointer;
}
.orderadd-container .main .left-side .left-side-main .order-info-row .order-info-counter img.inactive {
	opacity:.4;
	cursor:default;
}
.orderadd-container .main .left-side .left-side-main .order-info-row .order-info-price {
	width:100px;
	font-size:110%;
	text-align:right;
}
.orderadd-container .main .left-side .left-side-main .edit-row label {
	width:220px;
	color:#4a4d4f;
}
.orderadd-container .main .left-side .left-side-main .edit-row input.inline {
	width:240px;
}
.orderadd-container .main .left-side .order-info-summary-lite {
	padding:10px 5px;
	border-top:1px solid #f2f2f3;
}
.orderadd-container .main .left-side .order-info-summary-lite .summary-row {
	padding:0 5px;
}
.orderadd-container .main .left-side .order-info-summary-lite label {
	color:#6a6d6f;
}
.orderadd-container .main .left-side .order-info-summary-lite .summary-row .discount {
	color:#f33;
}
.orderadd-container .main .left-side .order-info-summary {
	padding:15px 10px 10px;
	background:#f2f2f3;
	border-radius:16px;
}
.orderadd-container .main .left-side .order-info-summary .order-info-summary-row {
	padding:0 10px;
}
.orderadd-container .main .left-side .order-info-summary .order-info-summary-row label {
	color:#636669;
}
.orderadd-container .main .left-side .order-info-summary .order-info-summary-row div.discount {
	color:#f33;
}
.orderadd-container .main .left-side .order-info-summary .order-info-summary-row.order-info-summary-total {
	margin-top:5px;
	font-size:120%;
	font-weight:bold;
	color:#000;
}
.orderadd-container .main .left-side .order-info-summary .order-info-summary-row.order-info-summary-total label {
	color:#000;
}
.orderadd-container .main .left-side .order-info-summary .buttons {
	margin-top:10px;
}
.orderadd-container .main .left-side .order-info-summary button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.orderadd-container .main .left-side .order-info-summary .btn-cancel {
	background:#fff;
}
.orderadd-container .main .left-side .order-info-summary .btn-save {
	background:#ffc060;
}
.orderadd-container .main .left-side .order-info-summary .btn-next {
	color:#fff;
	background:#1e1f20;
}
.orderadd-container .main .left-side .pickup-areas-final {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	flex-wrap:wrap;	
}
.orderadd-container .main .left-side .pickup-areas-final .pickup-area-final {
	width:49%;
	margin:5px 0;
	padding:10px;
	color:#1e1f20;
	cursor:pointer;
	border-radius:12px;
	border:2px solid #f2f2f3;
}
.orderadd-container .main .left-side .pickup-areas-final .pickup-area-final.pickup-area-final-select {
	border-color:#ffc060;
}
.orderadd-container .main .left-side .pickup-areas-final .pickup-area-final img {
	margin-right:10px;
}
.orderadd-container .main .left-side .pickup-areas-final .pickup-area-final span {
	display:inline-block;
	margin-left:35px;
	color:#6a6d6f;
	font-size:90%;
}
.orderadd-container .main .left-side .pickup-areas-final .pickup-area-final span i {
	font-style:normal;
	font-size:90%;
	color:#000;
}

.orderadd-container .main .menu-side {
	background:#f2f2f3;
}
.orderadd-container .main .menu-side .inner {
	display:flex;
	flex-direction:row;
	height:100%;
	background:#fff;
	border-left:1px solid #f2f2f3;
	padding-left:10px;
}
.orderadd-container .menu-side .categories-container {
	display:flex;
	flex-direction:row;
	width:100%;
}
.orderadd-container .menu-side .categories-list {
	width:230px;
	height:100%;
	display:flex;
	flex-direction:column;
	margin-right:10px;
	padding:10px;
	border-radius:12px;
	background:#f2f2f3;
}
.orderadd-container .menu-side .categories-list .list {
	height:100%;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.orderadd-container .menu-side .categories-list .category {
	position:relative;
	margin-bottom:4px;
	padding:10px 15px;
	border-radius:10px;
	cursor:pointer;
}
.orderadd-container .menu-side .categories-list .category:hover {
	background:#e5e6e6;
}
.orderadd-container .menu-side .categories-list .category:hover.category-select {
	background:#fff;
}
.orderadd-container .menu-side .categories-list .category-select {
	background:#fff;
}
.orderadd-container .menu-side .categories-list .category i {
	margin-left:5px;
	font-size:80%;
	font-style:normal;
	color:#999;
}
.orderadd-container .menu-side .categories-container .products-list {
	flex:1;
}
.orderadd-container .menu-side .categories-container .products-list .products-list-controls {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.orderadd-container .menu-side .categories-container .products-list .products-list-controls input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.orderadd-container .menu-side .categories-container .products-list .products-list-controls input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.orderadd-container .menu-side .categories-container .products-list .products-list-controls button {
	position:relative;
	top:1px;
	border:none;
	background:none;
	cursor:pointer;
}
.orderadd-container .menu-side .categories-container .products-list .products-list-controls button img {
	width:47px;
	height:47px;
}
.orderadd-container .menu-side .categories-container .products-list .container-inner {
	height:calc(100% - 70px);
	margin-top:10px;
	padding:0 0 10px 0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.orderadd-container .menu-side .categories-container .products-list table.items {
	width:100%;
	border-collapse:collapse;
}
.orderadd-container .menu-side .categories-container .products-list table.items thead {
	position:sticky;
	top:0;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
}
.orderadd-container .menu-side .categories-container .products-list table.items thead tr th,
.orderadd-container .menu-side .categories-container .products-list table.items tr td {
	padding:10px 0 10px 5px;
	text-align:left;
}
.orderadd-container .menu-side .categories-container .products-list table.items tr td {
	border-bottom:1px solid #f2f2f3;
}
.orderadd-container .menu-side .categories-container .products-list table.items tbody tr:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.orderadd-container .menu-side .categories-container .products-list table.items thead tr th {
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
}
.orderadd-container .menu-side .categories-container .products-list table.items .prices {
	width:70px;
	color:#6a6d6f;
	text-align:right;
}
.orderadd-container .menu-side .categories-container .products-list table.items .weight {
	width:70px;
	color:#6a6d6f;
	padding-right:5px;
	text-align:right;
}

.modal.modal-address-add {
	width:580px;
	height:380px;
}
.modal.modal-address-add .container-inner {
	position:relative;
	height:220px;
}
.modal.modal-address-add .container-inner .addresses-list {
	position:absolute;
	top:50px;
	width:100%;
	max-height:240px;
	padding:10px 0;
	color:#4a4d4f;
	border-radius:12px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	border:1px solid #f2f2f3;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
	z-index:999;
}
.modal.modal-address-add .container-inner .addresses-list div {
	padding:10px 50px 10px 20px;
}
.modal.modal-address-add .container-inner .addresses-list div:hover {
	color:#000;
	background-color:#f2f2f3;
	cursor:pointer;
}
.modal.modal-address-add .container-inner .address-form {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-top:15px;
}
.modal.modal-address-add .container-inner .address-form div {
	margin-right:10px;
}
.modal.modal-address-add .container-inner .address-form div:last-child {
	margin-right:0;
}
.modal.modal-address-add .container-inner .address-form div {
	position:relative;
}
.modal.modal-address-add .container-inner .address-form div span {
	display:inline-block;
	position:absolute;
	top:10px;
	right:6px;
	padding:3px 8px;
	font-size:85%;
	color:#4a4d4f;
	border-radius:5px;
	background:#f2f2f3;
}
.modal.modal-address-add .container-inner .address-form input:invalid + span {
	opacity:0;
}
.modal.modal-address-add .container-inner textarea {
	height:100px;
}
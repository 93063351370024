.component-semifinish-inline {
	padding:10px 0;
    border-bottom:1px solid #f1f3f4;
}
.component-semifinish-inline .component-semifinish-inline-title {
	display:flex;
	align-items:flex-start;
	justify-content:space-between;
}
.component-semifinish-inline .component-semifinish-inline-title .component-semifinish-inline-title-title h4 {
	margin:5px 0 0 0 !important;
}
.component-semifinish-inline table.components {
	width:100%;
	border-collapse:collapse;
}
.component-semifinish-inline table.components thead {
	border:1px solid #f2f2f3;
	border-left:none;
	border-right:none;
	background:#fff;
}
.component-semifinish-inline table.components thead tr th,
.component-semifinish-inline table.components tbody tr td {
	padding:10px 0 5px 2px;
	text-align:left;
	font-size:90%;
	font-weight:normal;
	color:#4a4d4f;
}
.component-semifinish-inline table.components tbody tr td {
	position:relative;
	padding:10px 0 5px 2px;
	text-align:left;
}
.component-semifinish-inline table.components tbody tr td.name {
	width:160px;
	padding-right:5px;
}
.component-semifinish-inline table.components tbody tr td.count {
	position:relative;
	width:80px;
	padding-right:5px;
}
.component-semifinish-inline table.components tbody tr td.count span {
	display:inline-block;
	position:absolute;
	top:18px;
	right:10px;
	padding:3px 6px;
	font-size:85%;
	color:#4a4d4f;
	border-radius:5px;
	background:#e5e6e6;
}
.component-semifinish-inline table.components thead tr th.sum,
.component-semifinish-inline table.components tbody tr td.sum {
	width:75px;
	padding-right:5px;
	text-align:right;
}
.component-semifinish-inline table.components tbody tr td.sum {
	font-size:100%;
	white-space:nowrap;
}
.component-semifinish-inline table.components tbody tr td.sum .input {
	width:100%;
	padding:7px 5px 7px 0;
	border-radius:5px;
	border:1px solid #ccc;
	background:#fcfcfc;
}
.component-semifinish-inline table.components tbody tr td.delete {
	width:20px;
}
.component-semifinish-inline table.components tbody tr td input {
	width:100%;
	padding:10px;
	border-radius:5px;
	border:1px solid #ccc;
	background:#fcfcfc;
}
.component-semifinish-inline table.components tbody tr td input:focus {
	border:1px solid #999;
	background:#fff;
}
.component-semifinish-inline table.components tbody tr td .measure {
	display:inline-block;
	position:absolute;
	top:17px;
	right:8px;
	padding:2px 5px;
	font-size:85%;
	color:#4a4d4f;
	border-radius:5px;
	border:2px solid #fff;
	background:#f2f2f3;
}
.component-semifinish-inline table.components tbody tr td img {
	position:relative;
	top:3px;
	width:18px;
	cursor:pointer;
}
.component-semifinish-inline table.components tbody tr td .components-list {
	position:absolute;
	top:43px;
	display:block;
	width:151px;
	max-height:160px;
	border:1px solid #999;
	border-top-color:#f2f2f3;
	border-radius:5px;
	border-radius:0 0 5px 5px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	overflow:auto;
	z-index:100;
}
.component-semifinish-inline table.components tbody tr td .components-list .components-list-item {
	display:flex;
	align-items:center;
	justify-content:space-between;
	padding:8px 10px;
	color:#4a4d4f;
	border-bottom:1px solid #f2f2f3;
	cursor:pointer;
}
.component-semifinish-inline table.components tbody tr td .components-list .components-list-item span {
	color:#4a4d4f;
	font-size:90%;
	border-radius:8px;
	padding:2px 6px;
	background:#e5e6e6;
	white-space:nowrap;
}
.component-semifinish-inline table.components tbody tr td .components-list .components-list-item:hover {
	background:#f2f2f3;
}
.component-semifinish-inline table.components tbody tr td .components-list .components-list-empty {
	padding:8px 10px;
	color:#f44336;
}
.component-semifinish-inline table.components tbody tr td .components-list .components-list-empty:hover {
	background:none;
	cursor:default;
}
.component-semifinish-inline table.components tbody tr.total {
	border-bottom:1px solid #eee;
}
.component-semifinish-inline table.components tbody tr.total td {
	padding-left:10px;
	font-size:100%;
	white-space:nowrap;
}
.component-semifinish-inline .buttons {
	margin:10px 0;
}
.component-semifinish-inline .buttons button {
	display:inline-block;
	height:40px;
	margin-right:10px;
	padding:10px 15px;
	font-size:100%;
	color:#000;
	font-weight:normal;
	border-radius:8px;
	border:none;
	background:#f2f2f3;
	cursor:pointer;
}
.component-semifinish-inline .buttons button:hover {
	background:#ccc;
	transition:all 1s;
}
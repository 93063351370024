.components-container .product-edit {
	display:flex;
	width:35%;
}
.components-container .product-edit .product-add-container {
	margin-top:10px;
}
.components-container .product-edit .product-add-container p {
	margin-bottom:10px;
	color:#4a4d4f;
}
.components-container .product-edit .product-add-container button {
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-list-container {
	display:flex;
	flex-direction:column;
	width:100%;
	margin-left:10px;
}
.components-container .product-edit .container-inner {
	width:100%;
	height:100%;
	overflow-y:auto;
}
.components-container .product-edit .container-inner .list {
	height:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.components-container .product-edit .container-inner .product-edit-row {
	padding:10px 0;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-row {
	padding:2px 0;
}
.components-container .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .product-edit .container-inner .product-edit-row label {
	width:150px;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-edit-row label.label-middle {
	width:280px;
}
.components-container .product-edit .container-inner .product-edit-row input,
.components-container .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.components-container .product-edit .container-inner .product-edit-row input:focus,
.components-container .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.components-container .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.components-container .product-edit .container-inner .product-edit-row input::placeholder,
.components-container .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.components-container .product-edit .container-inner .product-edit-row textarea.large {
	height:400px;
}
.components-container .product-edit .container-inner .product-edit-row .input-title,
.components-container .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.components-container .product-edit .container-inner .product-edit-row input[type='checkbox'] {
	width:auto !important;
	margin:0 10px 0 0;
}
.components-container .product-edit .container-inner .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.components-container .product-edit .container-inner .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.components-container .product-edit .container-inner .product-edit-row .select.select-middle {
	width:200px;
}
.components-container .product-edit .container-inner .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.components-container .product-edit .container-inner .product-edit-row input[type="file"] {
	display:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn {
	display:inline-block;
	margin-right:10px;
	padding:8px 24px;
	border:none;
	border-radius:8px;
	color:#1e1f20;
	font-size:100%;
	background:#eee;
	cursor:pointer;
	text-decoration:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn:hover {
	background:#ccc;
	transition:all 1s;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images {
	display:flex;
	flex-direction:row;
	align-items:center;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image {
	position:relative;
	width:88px;
	height:88px;
	margin-right:10px;
	margin-bottom:10px;
	border-radius:12px;
	overflow:hidden;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image.product-edit-image-error .delete {
	display:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image.product-edit-image-wide {
	width:100%;
	height:150px;
	border-radius:12px;
	overflow:hidden;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image.product-edit-image-wide img.image {
	width:100%;
	height:100%;
	object-fit:contain;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image.product-edit-image-wide img.image.image-error {
	object-fit:cover;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image img.image {
	width:100%;
	height:100%;
	object-fit:contain;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image img.image-error {
	object-fit:cover;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image .delete {
	position:absolute;
	top:calc(50% - 38px / 2);
	right:calc(50% - 38px / 2);
	width:38px;
	height:38px;
	background:url('./Images/delete-black-btn.svg') no-repeat 50% 50%;
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-components {
	display:flex;
	flex-direction:row;
	align-items:center;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin:0 10px 10px 0;
	padding:5px;
	padding-left:10px;
	border-radius:12px;
	border:1px solid #caccce;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component img {
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-edit-row .chk-req {
	margin-left:20px;
}
.components-container .product-edit .container-inner .product-edit-row .chk-req label {
	position:relative;
	top:-2px;
	font-size:85%;
	color:#666;
}
.components-container .product-edit .container-inner .product-view-row-simple .image {
	width:370px;
	height:150px;
	margin-right:20px;
	border-radius:16px;
	object-fit:contain;
}
.components-container .product-edit .container-inner .product-view-row-simple .image.image-error {
	object-fit:cover;
}
.components-container .product-edit .container-inner .product-view-row-simple .color {
	width:50px;
	height:10px;
	border-radius:5px;
}

.components-container .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.components-container .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.components-container .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-footer .btn-save {
	background:#ffc060;
}

.form-template {
	border-radius:10px;
	background:#f4f4f4;
	padding:15px 15px 5px;
}
.form-template .form-row {
	margin-bottom:10px;
}
.form-template .form-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.form-template .form-oneline-wide {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.form-template .form-row label {
	display:block;
	width:auto !important;
	color:#4a4d4f;
}
.form-template .form-row input {
	margin-top:5px;
}
.point-settings-container {
	width:100%;
}
.components-container.point-settings-container .categories-container {
	width:73%;
	margin-right:5px;
	border:none;
}
.components-container.point-settings-container .categories-container .products-list.products-list-settings {
	overflow-x:hidden;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner {
	padding-right:10px;
	height:calc(100% - 60px);
	margin-top:0;
}
.components-container.point-settings-container .categories-container .settings-container-footer {
	border-top:1px solid #f0f0f0;
	padding:10px 0 0 0;
}
.components-container.point-settings-container .categories-container .settings-container-footer .btn-save {
	width:100%;
	height:48px;
	padding:0 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	background:#ffc060;
	cursor:pointer;
}
.components-container.point-settings-container .categories-container .settings-container-footer .btn-wait {
	display:flex;
	justify-content:center;
	align-items:center;
	padding:11px 30px;
	color:#666;
	font-size:100%;
	text-align:center;
	border-radius:12px;
	background:#e0e0e0;
	cursor:wait;
}
.components-container.point-settings-container .categories-container .settings-container-footer .btn-wait img {
	width:32px;
	margin-right:10px;
}

.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .settings-content-title {
	padding:10px;
	padding-bottom:9px;
	padding-left:12px;
	font-weight:bold;
	font-size:120%;
	border-radius:8px;
	background:#f2f2f3;
	z-index:100;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section {
	width:100%;
	border-bottom:1px solid #ddd;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section {
	border-bottom:1px solid #f0f0f0;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section:last-child {
	border-bottom:none;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit {
	width:unset;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit .product-edit-list-container {
	width:unset;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit .product-edit-list-container .container-inner {
	height:initial;
	margin:0;
	padding:0;
	padding-bottom:15px;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit .product-edit-list-container .list {
	margin:0;
	padding-bottom:0;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit .product-edit-list-container .container-inner h4 {
	margin-top:10px;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit .product-edit-list-container .container-inner textarea {
	height:200px;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit .product-edit-list-container .product-view-row-description {
	font-size:85%;
	line-height:1.2;
	color:#777;
	font-style:italic;
}
.components-container.point-settings-container .categories-container .container-inner.settings-container-inner .section .product-edit .product-edit-list-container .product-edit-image {
	background:#f2f2f2;
}

.components-container.point-settings-container .product-edit {
	display:block;
	width:27%;
}
.components-container.point-settings-container .product-edit .settings-sections {
	height:calc(100vh - 155px);
	padding:15px 10px;
	border-radius:12px;
	background:#f9f9f9;
	overflow-y:auto;
    scrollbar-color:#bbb transparent;
    scrollbar-width:thin;
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	position:relative;
	padding:5px 5px 5px 10px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	cursor:pointer;
	border-bottom:1px solid #eee;
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item .arrow {
	width:28px;
	height:28px;
	border-radius:50%;
	display:flex;
	justify-content:center;
	align-items:center;
	background:transparent url(./Images/arrow-down.svg) no-repeat center center;
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item .arrow.active {
	transform:rotate(180deg);
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item:hover .arrow {
	border-radius:50%;
	background-color:#e5e5e5;
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item:first-child {
	border-top:1px solid #eee;
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item:hover {
	background:#f2f2f3;
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item-inner {
	padding:5px 0 5px 20px;
	font-size:95%;
	color:#4a4d4f;
	border-bottom:1px solid #eee;
	cursor:pointer;
}
.components-container.point-settings-container .product-edit .settings-sections .settings-sections-item-inner:hover {
	background:#f2f2f3;
}
.worktime-container {
	padding:20px;
	height:100%;
}

.worktime-container .categories-container {
	display:flex;
	flex-direction:row;
}
.worktime-container .categories-container .products-list {
	flex:1;
	width:100%;
	height:calc(100vh - 120px);
	overflow:hidden;
}
.worktime-container .categories-container .products-list .products-list-controls {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.worktime-container .categories-container .products-list .products-list-controls input.search {
	width:50%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.worktime-container .categories-container .products-list .products-list-controls input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.worktime-container .categories-container .products-list .products-list-controls .months {
	padding: 0 20px;
	width:100%;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.worktime-container .categories-container .products-list .products-list-controls .months .month-name {
	color:#4a4d4f;
	text-transform:capitalize;
}
.worktime-container .categories-container .products-list .products-list-controls .months button {
	position:relative;
	top:1px;
	border:none;
	background:none;
	cursor:pointer;
}
.worktime-container .categories-container .products-list .products-list-controls .months button img {
	width:47px;
	height:47px;
}
.worktime-container .categories-container .products-list .container-inner {
	height:calc(100% - 70px);
	margin-top:20px;
	padding-bottom:20px;
	overflow-y:auto;
	overflow-x:hidden;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.worktime-container .categories-container .products-list table.items {
	width:100%;
	border-collapse:collapse;
}
.worktime-container .categories-container .products-list table.items thead {
	position:sticky;
	top:0;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
}
.worktime-container .categories-container .products-list table.items thead tr th,
.worktime-container .categories-container .products-list table.items tr td {
	padding:10px 0 10px 5px;
	text-align:left;
}
.worktime-container .categories-container .products-list table.items tr td {
	border-bottom:1px solid #f2f2f3;
}
.worktime-container .categories-container .products-list table.items tbody tr:hover {
	background:#1d1d1d1f;
	cursor:pointer;
}
.worktime-container .categories-container .products-list table.items thead tr th {
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
}
.worktime-container .categories-container .products-list table.items tbody tr.row-selected {
	background:#e5e6e6;
}
.worktime-container .categories-container .products-list table.items tr td span {
	display:block;
	font-size:80%;
	color:#aaa;
}
.worktime-container .categories-container .products-list table.items .status {
	width:110px;
	padding:0 10px 0 0 !important;
	text-align:right;
}
.worktime-container .categories-container .products-list table.items .status .status-point {
	width:8px;
	height:8px;
	margin-right:8px;
	border-radius:50%;
}
.worktime-container .categories-container .products-list table.items .status0,
.worktime-container .categories-container .products-list table.items .status1,
.worktime-container .categories-container .products-list table.items .status9 {
	display:flex;
	flex-direction:row;
	align-items:baseline;
	justify-content:flex-end;
	color:#636669;
	font-size:90%;
}
.worktime-container .categories-container .products-list table.items .status .status0 .status-point {
	background:#ffc060;
}
.worktime-container .categories-container .products-list table.items .status .status1 .status-point {
	background:#4ad992;
}
.worktime-container .categories-container .products-list table.items .status .status9 .status-point {
	background:#2891fb;
}
.worktime-container .categories-container .products-list table.items .timetable {
	width:600px;
	padding:0;
	height:0;
}
.worktime-container .categories-container .products-list table.items .timetable-days {
	display:flex;
	flex-direction:row;
	width:100%;
	height:100%;
}
.worktime-container .categories-container .products-list table.items .timetable-days .day {
	flex:1;
	display:flex;
	align-items:center;
	justify-content:center;
	margin:1px;
}
.worktime-container .categories-container .products-list table.items .timetable-days .day:last-child {
	margin-right:0;
}
.worktime-container .categories-container .products-list table.items .timetable-days .day.weekend {
	color:#f00;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day {
	position:relative;
	opacity:.5;
	background:#f2f2f3;
}
.worktime-container .categories-container .products-list table.items td:hover .timetable-days .day {
	background:#fff;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day.added {
	background:#4ad991;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day.selected {
	background:#2891fb;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day:hover {
	opacity:1;
}
.worktime-container .categories-container .products-list table.items tbody tr.row-selected td.timetable .timetable-days .day {
	opacity:1;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day .tooltip {
	position:absolute;
	top:-85px;
	left:-75px;
	width:110px;
	padding:10px;
	font-size:90%;
	color:#4a4d4f;
	background:#fff;
	border:1px solid #f2f2f3;
	border-radius:8px;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	display:none;
	z-index:1002;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day .tooltip .delete {
	margin-top:10px;
	color:#f44337;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day .tooltip:before {
	content:'';
	position:absolute;
	top:100%;
	left:78px;
	width:0;
	height:0;
	border-left:5px solid transparent;
	border-right:5px solid transparent;
	border-top:5px solid #fff;
}
.worktime-container .categories-container .products-list table.items td.timetable .timetable-days .day.added:hover .tooltip {
	display:block;
}
.worktime-container .categories-container .products-list table.items .control {
	width:30px;
}
.worktime-container .categories-container .products-list table.items .control img {
	display:block;
	margin-left:auto;
	cursor:pointer;
}
.worktime-container .categories-container .products-list table.items .control .control-block {
	position:absolute;
	border-radius:8px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	padding:10px 0;
	color:#4a4d4f;
	z-index:1001;
}
.worktime-container .categories-container .products-list table.items .control .control-block div {
	padding:10px 50px 10px 20px;
}
.worktime-container .categories-container .products-list table.items .control .control-block div.settings {
	background:url('./Images/settings.svg') no-repeat center right 10px;
}
.worktime-container .categories-container .products-list table.items .control .control-block div.profile {
	background:url('./Images/user.svg') no-repeat center right 10px;
}
.worktime-container .categories-container .products-list table.items .control .control-block div.copy {
	background:url('./Images/copy.svg') no-repeat center right 10px;
}
.worktime-container .categories-container .products-list table.items .control .control-block div:hover {
	color:#000;
	background-color:#f2f2f3;
}
.worktime-container .categories-container .products-list .products-empty {
	margin-top:280px;
}
.worktime-container .categories-container .products-list .products-empty div {
	margin-top:20px;
	color:#4a4d4f;
}
.worktime-container .categories-container .products-list .products-empty div img {
	display:block;
}
.worktime-container .categories-container .products-list .products-empty button {
	margin-top:20px;
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}

.modal.modal-day {
	width:400px;
}
.modal.modal-day .day-info {
	margin-bottom:10px;
	text-transform:capitalize;
}
.modal.modal-day .day-info span {
	margin-left:10px;
	text-transform:capitalize;
	color:#96999c;
}
.modal.modal-day .header img {
	position:relative;
	top:-35px
}
.modal.modal-day .container-inner .user-info {
	margin-bottom:20px;
}
.modal.modal-day .container-inner .user-info div {
	font-size:90%;
	color:#96999c;
}
.modal.modal-day .container-inner .worktime {
	padding:5px 0 1px;
	border-top:1px solid #f2f2f3;
}
.modal.modal-day .container-inner .worktime .worktime-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:10px 0 30px;
}
.modal.modal-day .container-inner .worktime .worktime-row label {
	color:#4a4d4f;
	margin-right:10px;
}
.modal.modal-day .container-inner .worktime .worktime-row div {
	position:relative;
}
.modal.modal-day .container-inner .worktime .worktime-row div input {
	width:110px;
	text-align:right;
}
.modal.modal-day .container-inner .worktime .worktime-row div input.date {
	width:160px;
	text-align:right;
}
.modal.modal-day .worktime-reset {
	display:block;
	width:140px;
	margin:0 0 30px auto;
	padding:5px 10px;
	border:1px solid #f2f2f3;
	border-radius:10px;
	background:#f2f2f3;
	text-align:center;
	color:#4a4d4f;
	cursor:pointer;
}
.modal.modal-day .worktime-reset:hover {
	color:#fff;
	background:#f44337;
}
.modal.modal-day .notice {
	display:block;
	padding:10px 15px;
	color:#4a4d4f;
	font-size:95%;
	font-style:italic;
	border-radius:8px;
	background:#fffcc0;
}

.modal.modal-settings .header img {
	position:relative;
	top:unset;
}

.modal.modal-worktime-copy .select {
	width:100%;
	margin:10px 0;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.modal.modal-worktime-copy .select.select-middle {
	width:200px;
}
.modal.modal-worktime-copy select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
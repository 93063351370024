.components-container {
	display:flex;
	flex-direction:row;
	padding:20px;
	height:100%;
}

.components-container .categories-container {
	display:flex;
	flex-direction:row;
	width:65%;
	border-right:1px solid #f2f2f3;
}

.components-container .categories-container .products-list {
	flex:1;
}
.components-container .categories-container .products-list .products-list-controls {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.components-container .categories-container .products-list .products-list-controls input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.components-container .categories-container .products-list .products-list-controls input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.components-container .categories-container .products-list .products-list-controls button {
	position:relative;
	top:1px;
	border:none;
	background:none;
	cursor:pointer;
}
.components-container .categories-container .products-list .products-list-controls button img {
	width:47px;
	height:47px;
}
.components-container .categories-container .products-list .container-inner {
	height:calc(100% - 70px);
	margin-top:20px;
	padding:10px;
	padding-top:0;
	padding-left:0;
	padding-right:0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .categories-container .products-list .products-empty {
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	height:calc(100% - 60px);
}
.components-container .categories-container .products-list .products-empty div {
	margin-top:260px;
	text-align:center;
	color:#4a4d4f;
}
.components-container .categories-container .products-list .products-empty img {
	position:absolute;
	top:20px;
	right:30px;
}
.components-container .categories-container .products-list table.items {
	width:100%;
	border-collapse:collapse;
}
.components-container .categories-container .products-list table.items thead {
	position:sticky;
	top:0;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
}
.components-container .categories-container .products-list table.items thead tr th,
.components-container .categories-container .products-list table.items tr td {
	padding:10px 0 10px 5px;
	text-align:left;
}
.components-container .categories-container .products-list table.items tr td {
	border-bottom:1px solid #f2f2f3;
}
.components-container .categories-container .products-list table.items tbody tr:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.components-container .categories-container .products-list table.items thead tr th {
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items .hidden {
	color:#aaa;
}
.components-container .categories-container .products-list table.items .plus {
	color:#1e8148;
}
.components-container .categories-container .products-list table.items .minus {
	color:#e95352;
}
.components-container .categories-container .products-list table.items td.sources {
	font-size:80%;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items .sum {
	width:80px;
	text-align:right;
	white-space:nowrap;
}
.components-container .categories-container .products-list table.items .control {
	width:30px;
}
.components-container .categories-container .products-list table.items .control img {
	display:block;
	margin-left:auto;
	cursor:pointer;
}
.components-container .categories-container .products-list table.items .control .control-block {
	position:absolute;
	border-radius:8px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	padding:10px 0;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items .control .control-block div {
	padding:10px 50px 10px 20px;
}
.components-container .categories-container .products-list table.items .control .control-block div.delete {
	background:url('./Images/delete-grey.svg') no-repeat center right 10px;
}
.components-container .categories-container .products-list table.items .control .control-block div:hover {
	color:#000;
	background-color:#f2f2f3;
}

.components-container .product-edit {
	display:flex;
	width:35%;
}
.components-container .product-edit .product-add-container {
	margin-top:10px;
}
.components-container .product-edit .product-add-container p {
	margin-bottom:10px;
	color:#4a4d4f;
}
.components-container .product-edit .product-add-container button {
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-list-container {
	display:flex;
	flex-direction:column;
	width:100%;
	margin-left:10px;
}
.components-container .product-edit .container-inner {
	width:100%;
	height:100%;
	overflow-y:auto;
}
.components-container .product-edit .container-inner .list {
	height:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.components-container .product-edit .container-inner .product-edit-row {
	padding:10px 0;
}
.components-container .product-edit .container-inner .product-edit-row.product-edit-row-first {
	padding-top:2px;
}
.components-container .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .product-edit .container-inner .product-edit-row label {
	width:150px;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-edit-row label.label-middle {
	width:280px;
}
.components-container .product-edit .container-inner .product-edit-row input,
.components-container .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.components-container .product-edit .container-inner .product-edit-row input:focus,
.components-container .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.components-container .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.components-container .product-edit .container-inner .product-edit-row input::placeholder,
.components-container .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.components-container .product-edit .container-inner .product-edit-row .input-title,
.components-container .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.components-container .product-edit .container-inner .product-edit-row .select {
	width:100px;
	margin-left:10px;
	padding:15px;
	border-radius:8px;
	background:#f2f2f3;
}
.components-container .product-edit .container-inner .product-edit-row .select.select-wide {
	width:100%;
	margin:0;
}
.components-container .product-edit .container-inner .product-edit-row .select.select-middle {
	width:200px;
}
.components-container .product-edit .container-inner .product-edit-row select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn {
	display:inline-block;
	margin-right:10px;
	padding:8px 24px;
	border:none;
	border-radius:8px;
	color:#1e1f20;
	font-size:100%;
	background:#eee;
	cursor:pointer;
	text-decoration:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn:hover {
	background:#ccc;
	transition:all 1s;
}
.components-container .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.components-container .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.components-container .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-footer .btn-cancel.btn-cancel-wide {
	width:100%;
}
.components-container .product-edit .product-edit-footer .btn-save {
	background:#ffc060;
}
.components-container .product-edit .container-inner .product-view-row-notice {
	display:block;
	padding:10px 15px;
	color:#4a4d4f;
	font-style:italic;
	border-radius:8px;
	background:#fffcc0;
}
.components-container .product-edit .container-inner .product-view-row-notice.product-view-row-notice-alert {
	color:#e95352;
	background:#fde8e8;
}
.components-container .product-edit .container-inner .product-view-row-notice span {
	cursor:pointer;
	border-bottom:1px dashed #4a4d4f;
}
.components-container .product-edit .container-inner .product-view-row-notice-simple {
	display:inline-block;
	font-size:90% !important;
	line-height:1.3;
	color:#999 !important;
	font-style:italic;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox {
	display:flex;
	flex-direction:row;
	align-items:stretch;
	justify-content:space-between;
	flex-wrap:wrap;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item {
	width:49.5%;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	margin-bottom:10px;
	padding:12px 10px;
	border-radius:12px;
	border:1px solid #caccce;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item.product-view-cashbox-item-select {
	background:#ffc06050;
	border-color:#ffc060;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item .product-view-cashbox-item-title {
	line-height:1.2;
	font-size:100%;
	color:#000;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item .product-view-cashbox-item-data {
	margin-top:12px;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item .product-view-cashbox-item-data div {
	padding-bottom:10px;
	margin-bottom:10px;
	border-bottom:1px solid #caccce;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item.product-view-cashbox-item-select div:first-child {
	border-bottom:1px solid #ffc060;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item .product-view-cashbox-item-data div:last-child {
	border-bottom:none;
	margin:0;
	padding:0;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item .product-view-cashbox-item-data div h3 {
	font-size:140%;
}
.components-container .product-edit .container-inner .product-view-row-simple .product-view-cashbox .product-view-cashbox-item .product-view-cashbox-item-data div span {
	display:block;
	margin-bottom:3px;
	font-size:90%;
	font-weight:normal;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-view-row-warning {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-top:10px;
	padding:10px;
	color:#e95352;
	border-radius:12px;
	background:#f2f2f3;
}
.components-container .product-edit .container-inner .product-view-row-warning img {
	margin-right:10px;
}
.components-container .product-edit .container-inner h4 .product-view-row-title-notice {
	display:block;
	font-size:80%;
	font-weight:normal;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-edit-row-tabs {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .product-edit .container-inner .product-edit-row-tabs .product-edit-row-tab {
	width:48%;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	padding:13px 20px;
	border-radius:12px;
	background:#f6f6f6;
	border:2px solid transparent;
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-edit-row-tabs .product-edit-row-tab img {
	margin-right:5px;
}
.components-container .product-edit .container-inner .product-edit-row-tabs .product-edit-row-tab.active1 {
	color:#1e8148;
	border:2px solid #1e8148;
}
.components-container .product-edit .container-inner .product-edit-row-tabs .product-edit-row-tab.active2 {
	color:#e95352;
	border:2px solid #e95352;
}
.components-container .product-edit .container-inner .product-edit-order-block {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-bottom:15px;
	padding:5px 8px 5px 12px;
	border-radius:12px;
	border:1px solid #f2f2f3;
	background:#f6f6f6;
}
.components-container .product-edit .container-inner .product-edit-order-block .info {
	font-size:90%;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-edit-order-block img {
	width:20px;
	height:20px;
	margin-left:5px;
	margin-right:6px;
	padding:3px;
	background:#ddd;
	border-radius:50%;
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-edit-order-block img:hover {
	background:#e5e6e6;
}
.components-container .product-edit .container-inner .product-edit-order-block img {
	margin:0 0 0 5px;
}
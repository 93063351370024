.error-inner-container {
	padding:30px;
}
.error-inner-container img {
	width:60px;
	margin:0 0 20px -5px;
}
.error-inner-container h4 {
	margin-bottom:10px;
}
.error-inner-container p {
	margin-bottom:20px;
}
.error-inner-container button {
	margin-top:20px;
	padding:12px 30px;
	color:#fff;
	font-size:100%;
	background:#000;
	border:none;
	border-radius:12px;
	cursor:pointer;
}
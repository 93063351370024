.orders-container {
	display:flex;
	flex-direction:column;
	height:100vh;
	padding:20px;
}

.orders-container .orders-info-block {
	margin-left:5px;
	color:#4a4d4f;
}
.orders-container .orders-info-block span {
	margin-right:15px;
}
.orders-container .orders-info-block span:last-child {
	margin-right:20px;
}
.orders-container .orders-info-block span b {
	color:#000;
	font-weight:bold;
}
.orders-container .header {
	display:flex;
	align-items:center;
	justify-content:space-between;
	padding:10px 0;
}
.orders-container .header .switchers {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.orders-container .header .switchers .select {
	width:200px;
	margin-right:10px;
	padding:9px 15px;
	border-radius:8px;
	background:#f2f2f3;
}
.orders-container .header .switchers .select.select-paymenttype {
	width:150px;
}
.orders-container .header .switchers .select select {
	width:100%;
	border:none;
	margin:0;
	padding:0;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -3px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.orders-container .header .controls {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.orders-container .header .controls img.orders-search-icon {
	display:block;
	width:40px;
	height:40px;
	cursor:pointer;
}
.orders-container .header .controls input.search {
	width:340px;
	margin-right:5px;
	padding:11px 15px;
	padding-left:38px;
	font-size:100%;
	border-radius:8px;
	background:#f2f2f3 url('./Images/lupe.svg') no-repeat 8px center;
	border:1px solid #eee;
}
.orders-container .header .controls input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}

.orders-container .main {
	height:calc(100% - 165px);
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.orders-container .main.main-empty {
	height:calc(100% - 165px);
}
.orders-container .main .empty {
	display:flex;
	height:100%;
}
.orders-container .main .order-item {
	margin-bottom:5px;
	padding:8px 10px 8px 20px;
	border-radius:16px;
	background:#f3f2f2;
}
.orders-container .main .order-item .controls-row {
	position:relative;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.orders-container .main .order-item .controls-row .number {
	position:relative;
	width:80px;
	font-size:110%;
}
.orders-container .main .order-item .controls-row .number span {
	border-bottom:1px dashed #000;
	cursor:pointer;
}
.orders-container .main .order-item .controls-row .number .tooltip {
	position:absolute;
	top:24px;
	left:-10px;
	width:350px;
	margin-top:10px;
	padding:20px;
	font-size:90%;
	color:#4a4d4f;
	background:#fff;
	border:1px solid #f2f2f3;
	border-radius:6px;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	display:none;
	z-index:1002;
}
.orders-container .main .order-item .controls-row .number .tooltip:before {
	content:'';
	position:absolute;
	top:-10px;
	left:15px;
	width:0;
	height:0;
	border-left:10px solid transparent;
	border-right:10px solid transparent;
	border-bottom:10px solid #fff;
}
.orders-container .main .order-item .controls-row .number .tooltip ul li {
	font-size:90%;
	color:#000;
	padding-bottom:5px;
	margin-bottom:5px;
	border-bottom:1px solid #f2f2f3;
}
.orders-container .main .order-item .controls-row .number .tooltip ul li:last-child {
	padding-bottom:0;
	margin-bottom:0;
}
.orders-container .main .order-item .controls-row .number .tooltip ul li .row {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.orders-container .main .order-item .controls-row .number .tooltip ul li.noborder {
	border:none;
}
.orders-container .main .order-item .controls-row .number .tooltip ul li.creator {
	margin-top:20px;
}
.orders-container .main .order-item .controls-row .number .tooltip ul li .point {
	display:inline-block;
	width:10px;
	height:10px;
	margin-right:10px;
	border-radius:50%;
	background:#f2f2f3;
}
.orders-container .main .order-item .controls-row .number .tooltip ul li .user {
	color:#4a4d4f;
	text-align:right;
}
.orders-container .main .order-item .controls-row .number:hover .tooltip {
	display:block;
}
.orders-container .main .order-item .controls-row .status {
	margin-left:10px;
}
.orders-container .main .order-item .controls-row .payment-type {
	margin-left:10px;
}
.orders-container .main .order-item .controls-row .select {
	padding:8px 10px;
	border-radius:8px;
	background:#fff;
}
.orders-container .main .order-item .controls-row .select select {
	width:100%;
	border:none;
	margin:0;
	padding:0;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -3px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.orders-container .main .order-item .controls-row .status .select {
	width:210px;
}
.orders-container .main .order-item .controls-row .payment-type .select {
	width:110px;
}
.orders-container .main .order-item .controls-row .block {
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:10px;
	padding:8px;
	padding-right:10px;
	border-radius:8px;
	background:#fff;
}
.orders-container .main .order-item .controls-row .block img {
	width:20px;
	margin-right:5px;
}
.orders-container .main .order-item .controls-row .block.client {
	cursor:pointer;
	padding-right:8px;
}
.orders-container .main .order-item .controls-row .block.client img {
	width:21px;
	margin-right:0;
}
.orders-container .main .order-item .controls-row .block.client:hover {
	background:#ffffff95;
}
.orders-container .main .order-item .controls-row .block.client.client-no {
	cursor:not-allowed;
	background:#fff;
}
.orders-container .main .order-item .controls-row .block.cutlery {
	cursor:pointer;
}
.orders-container .main .order-item .controls-row .block.time {
	cursor:pointer;
}
.orders-container .main .order-item .controls-row .block.time.time-alert {
	background:#ffb3b3;
}
.orders-container .main .order-item .controls-row .datetime {
	position:absolute;
	top:38px;
	left:80px;
	width:250px;
	margin-top:10px;
	padding:20px 15px;
	font-size:90%;
	color:#4a4d4f;
	background:#fff;
	border:1px solid #f2f2f3;
	border-radius:6px;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	z-index:1003;
}
.orders-container .main .order-item .controls-row .datetime:before {
	content:'';
	position:absolute;
	top:-10px;
	left:15px;
	width:0;
	height:0;
	border-left:10px solid transparent;
	border-right:10px solid transparent;
	border-bottom:10px solid #fff;
}
.orders-container .main .order-item .controls-row .datetime b {
	margin-left:5px;
}
.orders-container .main .order-item .controls-row .datetime input {
	width:100%;
	margin-top:5px;
	padding:8px;
	border-radius:8px;
	border:1px solid #e5e6e6;
}
.orders-container .main .order-item .controls-row .datetime img {
	position:absolute;
	width:18px;
	top:5px;
	right:5px;
	cursor:pointer;
}
.orders-container .main .order-item .controls-row .controls {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:flex-end;
	margin-left:auto;
}
.orders-container .main .order-item .controls-row .controls img {
	display:block;
	width:37px;
	margin-left:8px;
	cursor:pointer;
}
.orders-container .main .order-item .controls-row .workers {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-left:10px;
	border-radius:8px;
	background:#fff;
}
.orders-container .main .order-item .controls-row .workers .worker {
	height:37px;
	padding:5px 10px;
	border-right:1px solid #f2f2f3;
	cursor:pointer;
}
.orders-container .main .order-item .controls-row .workers .worker .worker-name {
	max-width:110px;
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
}
.orders-container .main .order-item .controls-row .workers .worker:last-child {
	border-right:none;
}
.orders-container .main .order-item .controls-row .workers .worker.worker-more,
.orders-container .main .order-item .controls-row .workers .worker.worker-empty {
	padding-top:10px;
	color:#636669;
	font-size:90%;
	cursor:default;
}
.orders-container .main .order-item .controls-row .workers .worker.worker-more {
	font-weight:bold;
}
.orders-container .main .order-item .controls-row .workers .worker span {
	display:block;
	font-size:80%;
	line-height:1.3;
	color:#636669;
}
.orders-container .main .order-item .controls-row .workers .worker div {
	font-size:90%;
	line-height:1;
}
.orders-container .main .order-item .controls-row .workers .plus img {
	display:block;
	width:32px;
	margin:0 3px 0 8px;
	cursor:pointer;
}
.orders-container .main .order-item .order-item-info {
	display:flex;
	flex-direction:row;
	margin:10px 0 2px;
	padding-top:5px;
	border-top:1px solid #e5e6e6;
}
.orders-container .main .order-item .order-item-info .more {
	width:160px;
	color:#2f8cff;
	font-weight:bold;
	cursor:pointer;
}
.orders-container .main .order-item .order-item-info .price {
	width:130px;
	font-weight:bold;
	text-align:right;
}
.orders-container .main .order-item .order-item-info .discount {
	width:100px;
	font-size:95%;
	color:#636669;
	text-align:right;
}
.orders-container .main .order-item .order-item-info .delivery {
	width:180px;
	font-size:95%;
	color:#636669;
	text-align:right;
}
.orders-container .main .order-item .order-item-info .address {
	width:280px;
	font-size:95%;
	margin-left:55px;
}
.orders-container .main .order-item .order-item-info .address span {
	color:#636669;
}
.orders-container .main .order-item .order-item-info .address .map {
	display:inline-block;
	margin-left:5px;
	padding:2px 5px 1px;
	font-size:80%;
	font-weight:bold;
	color:#2f8cff;
	text-transform:uppercase;
	border-radius:6px;
	background:#ddd;
	cursor:pointer;
}
.orders-container .main .order-item .order-item-info .address .map:hover {
	background:#ccc;
}
.orders-container .main .order-item .order-item-info .comment {
	width:260px;
	margin-left:auto;
	color:#636669;
	font-size:80%;
	line-height:1.2;
}
.orders-container .main .order-item .order-item-products {
	margin:10px 0 5px;
}
.orders-container .main .order-item .order-item-products .product {
	width:460px;
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:2px 0;
	font-size:95%;
	color:#4a4d4f;
	border-bottom:1px solid #e5e6e6;
}
.orders-container .main .order-item .order-item-products .product:last-child {
	border-bottom:none;
}
.orders-container .main .order-item .order-item-products .product .name {
	width:300px;
}
.orders-container .main .order-item .order-item-products .product .quantity {
	width:60px;
	text-align:right;
}
.orders-container .main .order-item .order-item-products .product .price {
	width:100px;
	text-align:right;
}
.orders-container .main .order-item .order-item-products .product.product-option .name,
.orders-container .main .order-item .order-item-products .product.product-option .quantity,
.orders-container .main .order-item .order-item-products .product.product-option .price {
	font-size:90%;
	color:#999;
}

.order-info-panel {
	position:absolute;
	top:0;
	left:100%;
	right:0;
	width:0;
	height:100vh;
	background:#fff;
	box-shadow: 0 0 20px rgba(0,0,0,.1);
	border-right:1px solid #f2f2f3;
	overflow:hidden;
	z-index:999;
}
.order-info-panel-show {
	width:640px;
	left:600px;
	animation-name:panelSlide;
	animation-iteration-count:1;
	animation-timing-function:ease-in;
	animation-duration:.2s;
}
.order-info-panel .order-info-oneline {
	display:flex;
	align-items:center;
	justify-content:unset;
}
.order-info-panel .order-info-oneline-wide {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.order-info-panel .order-info-oneline-wrap {
	flex-wrap:wrap;
}
.order-info-panel .order-info-panel-inner {
	position:relative;
	width:100%;
	height:100vh;
	padding:20px;
	overflow:hidden;
}
.order-info-panel .order-info-panel-inner .order-header {
}
.order-info-panel .order-info-panel-inner .order-header.order-header-wide {
	padding-bottom:15px;
	border-bottom:1px solid #e5e6e6;
}
.order-info-panel .order-info-panel-inner .order-header .order-info-panel-close {
	width:40px;
	height:40px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:10px;
	border-radius:16px;
	background:#fff;
	box-shadow:0 0 10px rgba(0,0,0,.1);
	cursor:pointer;
}
.order-info-panel .order-info-panel-inner .order-header .select {
	width:210px;
	padding:8px 10px;
	padding-bottom:5px;
	border-radius:8px;
	background:#f2f2f3;
}
.order-info-panel .order-info-panel-inner .order-header .select.select-status {
	border:2px solid #f2f2f3;
}
.order-info-panel .order-info-panel-inner .order-header .select.select-status.select-border {
	border:2px solid #ffc060;
}
.order-info-panel .order-info-panel-inner .order-header .select.select-status div {
	margin-top:2px;
	line-height:.8;
	font-size:85%;
	color:#96999c;
}
.order-info-panel .order-info-panel-inner .order-header .select select {
	width:100%;
	margin:0;
	padding:0;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}
.order-info-panel .order-info-panel-inner .order-header a img {
	display:block;
	cursor:pointer;
}
.order-info-panel .order-info-panel-inner .order-tabs {
	margin:20px 0;
	padding:4px;
	border-radius:12px;
	background:#f2f2f3;
}
.order-info-panel .order-info-panel-inner .order-tabs .order-tab {
	width:30%;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:10px 0;
	border-radius:10px;
	cursor:pointer;
}
.order-info-panel .order-info-panel-inner .order-tabs .order-tab-active {
	background:#fff;
}
.order-info-panel .order-info-panel-inner .order-info-main {
	height:calc(100% - 130px);
	padding-bottom:30px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-main-inner {
	padding-left:5px;
	padding-right:5px;
}
.order-info-panel .order-info-panel-inner .order-info-main.order-info-main-order {
	height:calc(100% - 315px);
}
.order-info-panel .order-info-panel-inner .order-info-main.order-info-main-client {
	height:calc(100% - 60px);
	padding-top:10px;
	padding-bottom:0;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-empty {
	margin:20px 0;
}
.order-info-panel .order-info-panel-inner .order-info-main h4 {
	margin-top:20px;
}
.order-info-panel .order-info-panel-inner .order-info-main h4.first {
	margin-top:0;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-row {
	padding:10px 5px 10px 0;
	border-bottom:1px solid #e5e6e6;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-row label {
	color:#636669;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-row.order-info-row-noborder {
	border:none;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-row.order-info-row-option {
	padding:3px 5px 3px 10px;
	border-bottom:none;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-row.order-info-row-option:last-child {
	border-bottom:1px solid #e5e6e6;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-row.order-info-row-option div {
	font-size:95%;
	color:#999;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-orders {
	display:flex;
	flex-direction:row;
	align-items:stretch;
	justify-content:space-between;
	flex-wrap:wrap;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-orders .order-info-orderinfo {
	width:32%;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	margin-bottom:10px;
	padding:12px;
	border-radius:12px;
	border:1px solid #caccce;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-orders .order-info-orderinfo span {
	line-height:1.2;
	color:#4a4d4f;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-orders .order-info-orderinfo div {
	margin-top:5px;
	font-weight:bold;
	font-size:140%;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-address {
	width:100%;
	margin-bottom:10px;
	padding:15px;
	border-radius:12px;
	background:#f2f2f3;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-address:hover {
	background:#f2f2f3 url('./Images/edit.svg') no-repeat right 10px center;
	cursor:pointer;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-btn {
	display:inline-block;
	padding:12px 20px;
	border:none;
	border-radius:12px;
	color:#fff;
	font-size:100%;
	background:#000;
	cursor:pointer;
	text-decoration:none;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-btn-second {
	color:#000 !important;
	background:#f2f2f3 !important;
	margin-left:10px;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-counter {
	display:flex;
	flex-direction:row;
	align-items:center;
	width:110px;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-counter img {
	width:36px;
	cursor:pointer;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-counter img.inactive {
	opacity:.4;
	cursor:default;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-price {
	width:100px;
	font-size:110%;
	text-align:right;
}



.order-info-panel .order-info-panel-inner .order-info-main .order-info-counter {
	display:flex;
	flex-direction:row;
	align-items:center;
	width:110px;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-counter img {
	width:36px;
	cursor:pointer;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-counter img.inactive {
	opacity:.4;
	cursor:default;
}
.order-info-panel .order-info-panel-inner .order-info-main .order-info-price {
	width:100px;
	font-size:110%;
	text-align:right;
}




.order-info-panel .order-info-panel-inner .order-info-main .input-client {
	width:100%;
	padding:12px 15px;
	border-radius:8px;
	font-style:italic;
	border:1px solid transparent;
	color:#4a4d4f;
	background:#f6f6f6;
}
.order-info-panel .order-info-panel-inner .order-info-main .input-client:focus {
	color:#000;
	border:1px solid #e5e6e6;
	background:#fff;
}
.order-info-panel .order-info-panel-inner .order-info-summary {
	padding:15px;
	background:#f2f2f3;
	border-radius:16px;
}
.order-info-panel .order-info-panel-inner .order-info-summary .order-info-summary-row label {
	color:#636669;
}
.order-info-panel .order-info-panel-inner .order-info-summary .order-info-summary-row div.discount {
	color:#f33;
}
.order-info-panel .order-info-panel-inner .order-info-summary .order-info-summary-row.order-info-summary-total {
	margin-top:5px;
	font-size:110%;
	font-weight:bold;
	color:#000;
}
.order-info-panel .order-info-panel-inner .order-info-summary .order-info-summary-row.order-info-summary-total label {
	color:#000;
}
.order-info-panel .order-info-panel-inner .order-info-summary .buttons {
	margin-top:10px;
}
.order-info-panel .order-info-panel-inner .order-info-summary button {
	width:49%;
	height:50px;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.order-info-panel .order-info-panel-inner .order-info-summary .btn-cancel {
	background:#fff;
}
.order-info-panel .order-info-panel-inner .order-info-summary .btn-save {
	background:#ffc060;
}
.order-info-panel .order-info-panel-inner .order-info-summary .btn-disable {
	opacity:.4;
	color:#fff;
	background:#1e1f20;
	cursor:default;
}
.order-info-panel .order-info-panel-inner .order-info-summary button span {
	display:block;
	margin-top:2px;
	color:#333;
	font-size:80%;
}
.order-info-panel .order-info-main-order .order-props {
	padding:8px 10px;
	margin:0 5px 5px 0;
	border-radius:12px;
	background:#fff;
	border:1px solid #e5e6e6;
	white-space:nowrap;
}
.order-info-panel .order-info-main-order .order-props img {
}
.order-info-panel .order-info-main-order .order-props.order-props-date {
}
.order-info-panel .order-info-main-order .order-props.order-props-payment {
}
.order-info-panel .order-info-main-order .order-props.order-props-discount {
}
.order-info-panel .order-info-main-order .order-props.order-props-client {
	cursor:pointer;
}
.order-info-panel .order-info-main-order .order-props span {
	margin-left:5px;
}

.order-overlay {
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(255,255,255,.5);
	z-index:997;
	opacity:0;
}
.order-overlay.order-overlay-show {
	display:block;
	opacity:1;
	animation-name:overlayFadeIn;
	animation-iteration-count:1;
	animation-timing-function:ease-in;
	animation-duration:.1s;
}

@keyframes overlayFadeIn {
	0% {
		display:none;
		opacity:0;
	}
	100% {
		display:block;
		opacity:1;
	}
}
@keyframes panelSlide {
	0% {
		width:0;
		left:100%;
	}
	100% {
		width:55%;
		left:45%;
	}
}

.modal.modal-address-add {
	width:580px;
	height:380px;
}
.modal.modal-address-add .container-inner {
	position:relative;
	height:220px;
}
.modal.modal-address-add .container-inner .addresses-list {
	position:absolute;
	top:50px;
	width:100%;
	max-height:240px;
	padding:10px 0;
	color:#4a4d4f;
	border-radius:12px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	border:1px solid #f2f2f3;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
	z-index:999;
}
.modal.modal-address-add .container-inner .addresses-list div {
	padding:10px 50px 10px 20px;
}
.modal.modal-address-add .container-inner .addresses-list div:hover {
	color:#000;
	background-color:#f2f2f3;
	cursor:pointer;
}
.modal.modal-address-add .container-inner .address-form {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin-top:15px;
}
.modal.modal-address-add .container-inner .address-form div {
	margin-right:10px;
}
.modal.modal-address-add .container-inner .address-form div:last-child {
	margin-right:0;
}
.modal.modal-address-add .container-inner .address-form div {
	position:relative;
}
.modal.modal-address-add .container-inner .address-form div span {
	display:inline-block;
	position:absolute;
	top:10px;
	right:6px;
	padding:3px 8px;
	font-size:85%;
	color:#4a4d4f;
	border-radius:5px;
	background:#f2f2f3;
}
.modal.modal-address-add .container-inner .address-form input:invalid + span {
	opacity:0;
}
.modal.modal-address-add .container-inner textarea {
	height:100px;
}

.modal.modal-orders {
	width:900px;
	height:600px;
}
.modal.modal-orders .container-inner {
	height:calc(100% - 40px);
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}

.modal.modal-users {
	width:450px;
	height:600px;
	display:flex;
	flex-direction:column;
}
.modal.modal-users .btn-accept {
	width:100%;
}
.modal.modal-users input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.modal.modal-users input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal-users .users {
	height:100%;
}
.modal.modal-users .users .list {
	height:460px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-users .users .list .user {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:16px 5px 16px 5px;
	margin-right:10px;
	border-bottom:1px solid #e5e6e6;
}
.modal.modal-users .users .list .user div span {
	display:block;
	font-size:90%;
	color:#666;
}
.modal.modal-users .users .list .user-empty {
	margin-top:40px;
	text-align:center;
}

.modal.modal-map {
	width:900px;
	height:600px;
	padding:0;
	overflow:hidden;
}
.modal.modal-map .map-container {
	height:100%;
}
.modal.modal-map .map-container .map-address {
	position:absolute;
	bottom:40px;
	right:10px;
	min-width:300px;
	padding:10px 20px;
	border-radius:12px;
	background:rgba(255,255,255,.8);
}
.modal.modal-map .map-container .map-address .button {
	display:inline-block;
	margin-top:10px;
	padding:5px 10px;
	font-size:90%;
	border-radius:6px;
	background:#eee;
	cursor:pointer;
}
.modal.modal-map .map-container .map-address .button:hover {
	background:#ddd;
}
.modal.modal-map .map-close {
	position:absolute;
	top:15px;
	right:15px;
	width:40px;
	height:40px;
	display:flex;
	align-items:center;
	justify-content:center;
	box-shadow:0 0 10px rgba(0,0,0,.1);
	border-radius:50%;
	background:#fff;
	cursor:pointer;
}
.modal.modal-writeoffs {
	width:450px;
	height:360px;
	display:flex;
	flex-direction:column;
}
.modal.modal.modal-writeoffs .container-inner {
	height:calc(360px - 145px);
	width:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row {
	padding:5px 0;
}
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row input,
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row input:focus,
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row input::placeholder,
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row .input-title,
.modal.modal.modal-writeoffs .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.modal.modal.modal-writeoffs .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.modal.modal.modal-writeoffs .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.modal.modal.modal-writeoffs .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.modal.modal.modal-writeoffs .product-edit .product-edit-footer .btn-save {
	background:#000;
	color:#fff;
}
.current-conditions-actions {
	margin-top:10px;
	border-radius:10px;
}
.current-conditions-actions .condition-row {
	position:relative;
	margin:5px 0;
	padding:10px 20px;
	border-radius:8px;
	border:1px dashed #bbb;
}
.current-conditions-actions .condition-row .condition-row-inner {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.current-conditions-actions .condition-row .condition-row {
	margin-top:5px;
	padding-inline:10px;
	border:1px solid #ccc;
}
.current-conditions-actions .condition-title {
	margin:2px 5px 2px 0;
	font-weight:bold;
}
.current-conditions-actions .condition-row .condition-row .condition-title {
	display:inline-block;
	margin:2px 5px 2px 0;
	font-size:95%;
	font-weight:normal;
}
.current-conditions-actions .condition-concatenate-separator {
	position:absolute;
	margin:-15px 0 0 -12px;
	padding:3px 10px;
	font-size:80%;
	font-weight:bold;
	border-radius:10px;
	background:#ccc;
	z-index:100;
}
.current-conditions-actions .condition-row .condition-postfix {
	margin-left:5px;
	padding-right:6px;
}
.current-conditions-actions .condition-row .condition-prefix {
	margin-right:5px;
}
.current-conditions-actions .condition-value {
	margin:5px 0 0;
}
.current-conditions-actions code,
.current-conditions-actions .condition-compare,
.current-conditions-actions .condition-value-item,
.current-conditions-actions .condition-concatenate {
	display:inline-block;
	margin:2px 5px 2px 0;
	padding:1px 6px;
	border-radius:10px;
	font-size:90%;
	color:#333;
	background:#eee;
	white-space:nowrap;
}
.current-conditions-actions code {
	color:#000;
	background:#e5e5e5;
}
.current-conditions-actions .condition-value-item {
	color:#000;
	background:#e5e5e5;
}
.current-conditions-actions .condition-value-item.condition-value-item-datetime {
	margin:0;
	padding:0;
}
.current-conditions-actions .condition-value-item.condition-value-item-datetime code {
	margin:0;
}
.current-conditions-actions .condition-value pre {
	font-size:90%;
}
.current-conditions-actions .condition-remove {
	display:none;
}

.modal.modal-products {
	width:500px;
	height:600px;
	display:flex;
	flex-direction:column;
}
.modal.modal-conditions {
	width:1100px;
	height:700px;
	display:flex;
	flex-direction:column;
}
.modal.modal-products .btn-accept {
	width:100%;
}
.modal.modal-products input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.modal.modal-products input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.modal.modal-products .products {
	height:100%;
}
.modal.modal-products .products .list {
	height:400px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-products .products .list .product {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:16px 5px 16px 5px;
	border-bottom:1px solid #e5e6e6;
}
.modal.modal-products .products .list .product div span {
	display:block;
	font-size:90%;
	color:#666;
}
.modal.modal-products .products .list .product-empty {
	margin-top:40px;
	text-align:center;
}

.modal.modal-conditions .conditions {
	height:608px;
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:space-between;
	border-radius:16px;
}
.modal.modal-conditions .conditions .sections {
	width:300px;
	height:100%;
	padding:10px;
	border-radius:16px;
	background:#f2f2f3;
}
.modal.modal-conditions .conditions .sections .inner {
	width:100%;
	height:100%;
	padding-right:5px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-conditions .conditions .content {
	width:calc(100% - 300px);
	height:100%;
	padding:0 0 0 20px;
}
.modal.modal-conditions .conditions .sections h4 {
	margin-bottom:4px;
	padding:10px 15px 0 15px;
}
.modal.modal-conditions .conditions .sections .section {
	display:block;
	position:relative;
	margin-bottom:4px;
	padding:10px 15px;
	border-radius:10px;
	color:#000;
	text-decoration:none;
	cursor:pointer;
}
.modal.modal-conditions .conditions .sections .section:hover {
	background:#e5e6e6;
}
.modal.modal-conditions .conditions .sections .section:hover.section-select {
	background:#fff;
}
.modal.modal-conditions .conditions .sections .section-select {
	background:#fff;
}
.modal.modal-conditions .conditions .content .empty {
	margin-top:200px;
}
.modal.modal-conditions .conditions .content .condition {
	height:100%;
}
.modal.modal-conditions .conditions .content .condition h3 {
	margin-top:5px;
}
.modal.modal-conditions .conditions .content .condition .condition-block {
	height:520px;
	margin-top:10px;
}
.modal.modal-conditions .conditions .content .condition .condition-block .condition-content {
	height:100%;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.modal.modal-conditions .condition .condition-row {
	position:relative;
	padding:10px 20px;
	border-radius:8px;
	opacity:.8;
	background:#f2f2f3;
}
.modal.modal-conditions .condition .condition-row:hover {
	transition:opacity .2s ease;
	opacity:1;
}
.modal.modal-conditions .condition .condition-row .condition-remove {
	position:absolute;
    top:5px;
    right:5px;
    width:22px;
    height:22px;
	text-align:center;
	line-height:24px;
    color:#333;
    font-size:18px;
    border-radius:50%;
    background:hsla(0, 0%, 100%, .5);
    opacity:0;
    transition:opacity .2s ease;
	cursor:pointer;
    z-index:99;
}
.modal.modal-conditions .condition .condition-row .condition-remove:hover {
	color:#fff;
	background:#f00;
}
.modal.modal-conditions .condition .condition-row:hover .condition-remove {
	opacity:1;
}
.modal.modal-conditions .condition .condition-row .condition-row-inner {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.modal.modal-conditions .condition .condition-row .condition-row {
	margin-top:5px;
	padding-inline:15px;
	border:1px solid #ccc;
	background:#f4f4f4;
}
.modal.modal-conditions .condition .condition-title {
	margin:0 5px 5px 0;
	font-size:110%;
	font-weight:bold;
}
.modal.modal-conditions .condition .condition-row .condition-row .condition-title {
	display:inline-block;
	margin:0;
	font-size:100%;
}
.modal.modal-conditions .condition .condition-row .condition-row-inner .condition-title {
	margin-bottom:0;
}
.modal.modal-conditions .condition .condition-concatenate {
	display:inline-block;
	margin-right:5px;
	padding:6px 12px 8px;
	font-size:110%;
	border-radius:6px;
	background:#ddd;
}
.modal.modal-conditions .condition .condition-row .condition-row .condition-concatenate {
	font-size:100%;
}
.modal.modal-conditions .condition .condition-concatenate-separator {
	position:absolute;
	margin:-11px 0 0 -12px;
	padding:5px 16px;
	font-size:80%;
	font-weight:bold;
	border-radius:10px;
	background:#b1c7e3;
	z-index:100;
}
.modal.modal-conditions .condition .condition-concatenate-separator.condition-concatenate-separator-and {
	background:#bae1cf;
}
.modal.modal-conditions .condition .condition-compare {
	display:inline-block;
	margin-right:5px;
	padding:8px 10px;
	border-radius:6px;
	background:#ddd;
}
.modal.modal-conditions .condition .condition-compare select {
	font-size:110%;
	border:none;
	background:none;
}
.modal.modal-conditions .condition .condition-row .condition-row .condition-compare {
	margin-left:5px;
	padding:5px 6px;
}
.modal.modal-conditions .condition .inline {
	width:62px;
	margin:0 5px;
	padding:9px 12px 10px 0;
	border-radius:6px;
	text-align:right;
}
.modal.modal-conditions .condition .inline.inline-wide {
	width:100%;
	margin-left:0;
	padding-left:12px;
	text-align:left;
}
.modal.modal-conditions .condition .condition-row .condition-row .inline {
	width:62px;
	padding:5px 10px 5px 0;
}
.modal.modal-conditions .condition .condition-row .inline.inline-date,
.modal.modal-conditions .condition .condition-row .inline.inline-time {
	width:135px;
	padding:7px 12px 8px 0;
	margin:0 5px;
}
.modal.modal-conditions .condition .condition-row .inline.inline-time {
	width:105px;
}
.modal.modal-conditions .condition .condition-row .condition-row .inline.inline-date,
.modal.modal-conditions .condition .condition-row .condition-row .inline.inline-time {
	padding:5px 12px 6px 0;
}
.modal.modal-conditions .condition .condition-value {
	margin:10px 0 0;
}
.modal.modal-conditions .condition .condition-value-item {
	display:inline-block;
	margin:0 5px 0 0;
	font-size:100%;
	padding-left:12px;
	border-radius:6px;
	background:#ddd;
	overflow:hidden;
}
.modal.modal-conditions .condition .condition-value-item .condition-value-remove {
	display:inline-block;
	margin-left:10px;
	padding:5px 10px 5px 8px;
	cursor:pointer;
	border-left:1px solid #bbb;
}
.modal.modal-conditions .condition .condition-value-item .condition-value-remove:hover {
	background:#f00;
	color:#fff;
}
.modal.modal-conditions .condition .condition-value-item.condition-value-item-button {
	padding:5px 12px 5px 12px;
	color:#fff;
	background:#000;
	border-left-color:#f00;
	cursor:pointer;
}
.modal.modal-conditions .condition .condition-value-item.condition-value-item-datetime {
	margin:0;
	padding-left:0;
	background:none;
}
.modal.modal-conditions .condition .condition-row .condition-postfix {
	margin-left:5px;
}
.modal.modal-conditions .condition .condition-row .condition-prefix {
	margin-right:5px;
}
.modal.modal-conditions .condition .button-row {
	margin:10px 0 0;
}
.modal.modal-conditions .condition .button-add {
	display:inline-block;
	padding:8px 20px;
	border:none;
	border-radius:8px;
	color:#fff;
	font-size:100%;
	background:#000;
	
}
.modal.modal-conditions .conditions .content .condition .condition-block .buttons {
	margin-top:8px;
}
.modal.modal-conditions .conditions .content .condition.condition-view h3 {
	margin-bottom:10px;
}
.modal.modal-conditions .conditions .content .condition.condition-view .condition-block {
	margin:0;
	height:100%;
}
.modal.modal-conditions .conditions .content .condition.condition-view .condition-title {
	margin:0 5px 5px 0;
}
.modal.modal-conditions .conditions .content .condition.condition-view code,
.modal.modal-conditions .conditions .content .condition.condition-view .condition-compare,
.modal.modal-conditions .conditions .content .condition.condition-view .condition-value-item,
.modal.modal-conditions .conditions .content .condition.condition-view .condition-concatenate {
	display:inline-block;
	margin:0 5px 0 0;
	font-size:100%;
	padding:4px 10px;
	border-radius:6px;
	background:#ddd;
}
.modal.modal-conditions .conditions .content .condition.condition-view .condition-row {
	opacity:1;
	margin-bottom:10px;
	cursor:pointer;
}
.modal.modal-conditions .conditions .content .condition.condition-view .condition-row .condition-row {
	margin-bottom:0;
	margin-top:5px;
}

.modal.modal-conditions-children .conditions-list {
}
.modal.modal-conditions-children .conditions-list .condition-list-item {
	padding:10px 5px;
	border-bottom:1px solid #f2f2f3;
}
.modal.modal-conditions-children .conditions-list .condition-list-item:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.order-info {
	margin-bottom:10px;
	margin-right:5px;
	padding:10px 15px;
	border-radius:16px;
	background: #f2f2f3;
}
.order-info:last-child {
	margin-bottom:0;
}
.order-info .order-info-header {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.order-info .order-info-header .order-info-number {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.order-info .order-info-header .order-info-number span {
	display:inline-block;
	margin-left:10px;
	padding:4px 8px;
	font-size:60%;
	font-weight:bold;
	color:#4a4d4f;
	border-radius:8px;
	background:#caccce;
	text-transform:uppercase;
}
.order-info .order-info-header .amounts {
	text-align:right;
}
.order-info .order-info-header .amounts b {
	font-size:110%;
	color:#2891fb;
}
.order-info .order-info-header .amounts .delivery {
	display:block;
	font-size:80%;
	color:#636669;
}
.order-info .order-info-header .amounts .surrender {
	display:block;
	font-size:80%;
}
.order-info .order-info-info {
	display:flex;
	flex-direction:row;
	align-items:flex-end;
	justify-content:space-between;
	margin-top:5px;
}
.order-info .order-info-info .date {
	font-size:80%;
	color:#96999c;
}
.order-info .order-info-info .address {
	margin-top:5px;
	color:#636669;
	font-size:90%;
}
.order-info .order-info-info .show-more {
	display:inline-block;
	padding:4px 8px 2px;
	font-size:70%;
	font-weight:bold;
	color:#1e1f20;
	border-radius:8px;
	background:#fff;
	text-transform:uppercase;
	cursor:pointer;
}
.order-info .order-info-info .show-more:hover {
	background:#ccc;
}
.order-info .order-info-main {
	margin-top:20px;
	padding:10px;
	border-radius:12px;
	border:1px solid #caccce40;
	background:rgba(255,255,255,0.2);
}
.order-info .order-info-main .order-info-main-top {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-bottom:20px;
}
.order-info .order-info-main .order-info-main-top .order-props {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:6px;
	margin-right:6px;
	border-radius:10px;
	background:#fff;
	white-space:nowrap;
}
.order-info .order-info-main .order-info-main-top .order-props.order-props-client {
	cursor:pointer;
}
.order-info .order-info-main .order-info-main-top .order-props.order-props-client a {
	font-weight:bold;
	color:#1e1f20;
	text-decoration:none;
}
.order-info .order-info-main .order-info-main-top .order-props img {
}
.order-info .order-info-main .order-info-main-top .order-props span {
	margin:0 5px;
}
.order-info .order-info-main .order-notice {
	display:block;
	margin-bottom:10px;
	padding:10px 15px;
	color:#4a4d4f;
	font-size:95%;
	font-style:italic;
	border-radius:8px;
	background:#fffcc090;
}
.order-info .order-info-main .order-notice b {
	display:block;
}
.order-info .order-info-main .order-info-products {
	margin-bottom:20px;
}
.order-info .order-info-main .order-info-products .order-info-product {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding:8px 2px;
	border-bottom:1px dashed #caccce90;
}
.order-info .order-info-main .order-info-products .order-info-product .name {
	color:#4a4d4f;
}
.order-info .order-info-main .order-info-products .order-info-product .count-price {
	margin-left:auto;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.order-info .order-info-main .order-info-products .order-info-product .count-price .quantity {
	color:#4a4d4f;
}
.order-info .order-info-main .order-info-products .order-info-product .count-price .amount {
	width:120px;
	margin-left:10px;
	font-weight:bold;
	text-align:right;
}
.order-info .order-info-main .order-info-products .order-info-product.order-info-product-option {
	padding:2px 2px 2px 10px;
	border:none;
}
.order-info .order-info-main .order-info-products .order-info-product.order-info-product-option .name,
.order-info .order-info-main .order-info-products .order-info-product.order-info-product-option .count-price,
.order-info .order-info-main .order-info-products .order-info-produc.order-info-product-option .count-price .quantity,
.order-info .order-info-main .order-info-products .order-info-product.order-info-product-option .count-price .amount {
	font-size:90%;
	font-weight:normal;
	color:#636669;
}

.order-info .order-info-main .order-info-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-top:20px;
	padding-top:15px;
	border-top:1px solid #caccce90;
}
.order-info .order-info-main .order-info-footer-noclient {
	padding-top:0;
	border-top:none;
}
.order-info .order-info-main .order-info-footer .order-info-footer-row {
	margin-right:30px;
	color:#4a4d4f;
}
.statistic-container {
	display:flex;
	flex-direction:column;
	height:100vh;
	padding:20px;
}
.statistic-container .header {
	display:flex;
	align-items:center;
	justify-content:space-between;
	padding-bottom:10px;
}
.statistic-container .header .select {
	width:200px;
	margin-right:10px;
	padding:8px 12px;
	border-radius:8px;
	background:#f2f2f3;
}
.statistic-container .header select {
	width:100%;
	border:none;
	font-size:100%;
	background:rgba(0,0,0,0) url('./Images/arrow-down.svg') no-repeat right -6px center;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}

.statistic-container .main {
	height:calc(100% - 115px);
	padding-bottom:20px;
	padding-top:10px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.statistic-container .main .empty {
	display:flex;
	height:100%;
}
.statistic-container .main .stat-oneline {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:flex-start;
}
.statistic-container .main .stat-oneline-wide {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.statistic-container .main .stat-oneline-up {
	align-items:flex-start;
}
.statistic-container .main .stat-oneline-bottom {
	align-items:flex-end;
}
.statistic-container .main .charts-big-blocks,
.statistic-container .main .charts-small-blocks {
	flex-wrap:wrap;
}
.statistic-container .main .charts-small-blocks .chart {
	width:calc(25% - 10px);
	margin:5px;
	padding:15px 20px;
	border-radius:8px;
	background:#fff;
	box-shadow:0 0 10px rgba(0,0,0,.1);
}
.statistic-container .main .charts-small-blocks .chart .title {
	color:#202224;
}
.statistic-container .main .charts-small-blocks .chart h3 {
	margin-top:10px;
}
.statistic-container .main .charts-small-blocks .chart .trends {
	margin:20px 0 5px;
}
.statistic-container .main .charts-small-blocks .chart .trends img {
	margin-right:5px;
}
.statistic-container .main .charts-small-blocks .chart .trends span {
	display:inline-block;
	margin-right:5px;
	font-size:90%;
	white-space:nowrap;
}
.statistic-container .main .charts-small-blocks .chart .trends span.up {
	color:#00a650;
}
.statistic-container .main .charts-small-blocks .chart .trends span.down {
	color:#ff3b30;
}
.statistic-container .main .charts-big-blocks {
	flex-wrap:wrap;
}
.statistic-container .main .charts-big-blocks .chart {
	width:calc(50% - 10px);
	margin:5px;
	padding:15px 20px;
	border-radius:8px;
	background:#fff;
	box-shadow:0 0 10px rgba(0,0,0,.1);
}
.statistic-container .main .charts-big-blocks .chart .title {
	margin-bottom:20px;
	color:#202224;
}
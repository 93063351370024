.components-categories-list {
	width:270px;
	margin-right:20px;
}
.components-categories-list .components-categories-list-title {
	display:flex;
	align-items:center;
	justify-content:space-between;
	margin:10px 0 0 5px;
}
.components-categories-list .components-categories-list-title h2 {
	font-size:24px;
	white-space:nowrap;
}
.components-categories-list .components-categories-list-title img {
	display:block;
	width:20px;
	height:20px;
}
.components-categories-list .categories-list-switcher {
	position:relative;
	margin-top:10px;
	padding-top:10px;
	width:100%;
	height:38px;
}
.components-categories-list .categories-list-switcher.categories-list-switcher-disabled {
	opacity:.6;
}
.components-categories-list .categories-list-switcher-menu {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	font-weight:bold;
	color:#555;
	border-radius:12px;
	border:2px solid #eee;
	background:#fff;
	z-index:801;
	overflow:hidden;
}
.components-categories-list .categories-list-switcher-menu.categories-list-switcher-menu-open {
	box-shadow:0 0 10px rgba(0,0,0,0.1);
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu-item {
	padding:10px 15px;
	border-bottom:1px solid #eee;
	text-overflow:ellipsis;
	overflow:hidden;
	white-space:nowrap;
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu-item:hover {
	background:#f9f9f9;
	cursor:pointer;
}
.components-categories-list .categories-list-switcher-disabled .categories-list-switcher-menu-item:hover:hover {
	cursor:not-allowed !important;
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu-item:last-child {
	border-bottom:none;
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu-item b,
.components-categories-list .categories-list-switcher .categories-list-switcher-menu-item span {
	display:block;
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu-item span {
	font-size:90%;
	font-weight:normal;
	color:#4a4d4f;
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu .categories-list-switcher-menu-item.categories-list-switcher-menu-item-select {
	background: #f2f2f2 url('./Images/check.svg') no-repeat right 10px center;
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu .categories-list-switcher-menu-item.categories-list-switcher-menu-item-single {
	background-image:url('./Images/arrow-down.svg');
	background-repeat:no-repeat;
	background-position:right 5px center;
}
.components-categories-list .categories-list-switcher .categories-list-switcher-menu-item.categories-list-switcher-menu-item-add {
	display:block;
	font-weight:normal;
	color:#4a4d4f;
	background-image:url('./Images/plus.svg');
	background-repeat:no-repeat;
	background-position:right 5px center;
	text-decoration:none;
}

.components-categories-list .categories-list-container {
	height:calc(100vh - 220px);
	display:flex;
	flex-direction:column;
	margin-top:20px;
	border-radius:12px;
	background:#f2f2f3;
	overflow:hidden;
}
.components-categories-list .categories-list-container .container-inner {
	height:100%;
	overflow-y:auto;
	padding:10px;
}
.components-categories-list .categories-list-container .container-inner .list {
	height:100%;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-categories-list .categories-list-container .category {
	display:block;
	position:relative;
	margin-bottom:4px;
	padding:10px 15px;
	border-radius:10px;
	color:#000;
	text-decoration:none;
	cursor:pointer;
}
.components-categories-list .categories-list-container .category-section .category-section-title {
	margin-bottom:4px;
	padding:10px 15px;
	font-weight:bold;
	color:#000;
}
.components-categories-list .categories-list-container .category:hover {
	background:#e5e6e6;
}
.components-categories-list .categories-list-container .category:hover.category-select {
	background:#fff;
}
.components-categories-list .categories-list-container .category-select {
	background:#fff;
}

.categories-list-switcher-overlay {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:800;
	background:rgba(0,0,0,0.3);
}

.categories-tabs {
	padding:6px 6px 0 6px;
	background:rgba(0,0,0,0.05);
}
.categories-tabs .tabs {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:flex-start;
}
.categories-tabs .tabs .tab {
	display:flex;
	align-items:center;
	justify-content:center;
	width:50%;
	margin-right:5px;
	padding:7px 5px 5px;
	color:#666;
	border-radius:10px 10px 0 0;
	cursor:pointer;
}
.categories-tabs .tabs .tab:last-child {
	margin-right:0;
}
.categories-tabs .tabs .tab:hover {
	background:rgba(0,0,0,0.07);
}
.categories-tabs .tabs .tab.active,
.categories-tabs .tabs .tab.active:hover {
	color:#000;
	background:#f2f2f3;
}
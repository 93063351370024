.components-container {
	display:flex;
	flex-direction:row;
	padding:20px;
	height:100%;
}

.components-container .categories-container {
	display:flex;
	flex-direction:row;
	width:65%;
	border-right:1px solid #f2f2f3;
}

.components-container .categories-container .products-list {
	flex:1;
}
.components-container .categories-container .products-list .container-inner {
	margin-top:20px;
	padding:10px;
	padding-top:0;
	padding-left:0;
	padding-right:0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .categories-container .products-list table.items {
	width:100%;
	border-collapse:collapse;
}
.components-container .categories-container .products-list table.items thead {
	position:sticky;
	top:0;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
}
.components-container .categories-container .products-list table.items-next thead tr th,
.components-container .categories-container .products-list table.items-next tr td {
	padding:10px 0 10px 5px;
	text-align:left;
}
.components-container .categories-container .products-list table.items tr td {
	border-bottom:1px solid #f2f2f3;
}
.components-container .categories-container .products-list table.items tbody tr:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.components-container .categories-container .products-list table.items thead tr th {
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items tr td span {
	display:block;
	font-size:80%;
	color:#aaa;
}
.components-container .categories-container .products-list table.items tbody tr.row-selected {
	background:#e5e6e6;
}
.components-container .categories-container .products-list table.items tbody tr td.tariff {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .categories-container .products-list table.items tbody tr td.tariff img {
	width:20px;
	height:20px;
	margin-right:15px;
}

.components-container .product-edit {
	display:flex;
	width:35%;
}
.components-container .product-edit .product-add-container {
	margin-top:10px;
}
.components-container .product-edit .product-add-container p {
	margin-bottom:10px;
	color:#4a4d4f;
}
.components-container .product-edit .product-add-container button {
	padding:14px 30px;
	border-radius:12px;
	border:none;
	cursor:pointer;
	color:#1e1f20;
	font-size:100%;
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-list-container {
	display:flex;
	flex-direction:column;
	width:100%;
	margin-left:10px;
}
.components-container .product-edit .container-inner {
	width:100%;
	height:100%;
	overflow-y:auto;
}
.components-container .product-edit .container-inner .list {
	height:100%;
	padding-right:5px;
	padding-bottom:20px;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .product-edit .container-inner h4 {
	margin:20px 0 5px;
}
.components-container .product-edit .container-inner .product-edit-row {
	position:relative;
	padding:10px 0;
}
.components-container .product-edit .container-inner .product-edit-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .product-edit .container-inner .product-view-row {
	padding:10px 0;
	border-bottom:1px solid #f2f2f3;
}
.components-container .product-edit .container-inner .product-view-row-simple {
	padding:10px 0;
	border:none;
}
.components-container .product-edit .container-inner .product-view-row-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.components-container .product-edit .container-inner .product-view-row-oneline-start {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:flex-start;
}
.components-container .product-edit .container-inner .product-view-row-oneline-up {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:space-between;
}
.components-container .product-edit .container-inner .product-view-row .avatar {
	width:100px;
	height:100px;
	margin-right:20px;
	border-radius:26px;
	background:#f2f2f3;
	object-fit:cover;
}
.components-container .product-edit .container-inner .product-view-row h3 {
	margin-top:10px;
}
.components-container .product-edit .container-inner .product-view-row span.user-type {
	display:block;
	font-size:90%;
	color:#4a4d4f;
	margin-top:5px;
}
.components-container .product-edit .container-inner .product-view-row span {
	font-size:100%;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-view-row img.edit {
	margin-top:5px;
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-view-row .comment,
.components-container .product-edit .container-inner .product-view-row .description {
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-edit-row label {
	width:150px;
	color:#4a4d4f;
}
.components-container .product-edit .container-inner .product-edit-row label.label-middle {
	width:280px;
}
.components-container .product-edit .container-inner .product-edit-row input,
.components-container .product-edit .container-inner .product-edit-row textarea {
	width:100%;
	padding:16px;
	border-radius:8px;
	border:none;
	border:1px solid #e5e6e6;
	background:#fcfcfc;
	font-size:100%;
	resize:none;
}
.components-container .product-edit .container-inner .product-edit-row input:focus,
.components-container .product-edit .container-inner .product-edit-row textarea:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.components-container .product-edit .container-inner .product-edit-row textarea {
	height:120px;
}
.components-container .product-edit .container-inner .product-edit-row input::placeholder,
.components-container .product-edit .container-inner .product-edit-row textarea::placeholder {
	color:#caccce
}
.components-container .product-edit .container-inner .product-edit-row .input-title,
.components-container .product-edit .container-inner .product-edit-row .input-title:focus {
	padding:5px 0 0 2px;
	font-size:180%;
	font-weight:bold;
	border:none;
	background:none;
}
.components-container .product-edit .container-inner .product-edit-row input[type="file"] {
	display:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-check {
	position:absolute;
	top:15px;
	right:6px;
	padding:10px 12px 9px;
	font-size:90%;
	color:#555;
	border-radius:8px;
	background:#f2f2f3;
	transition:.3s;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-check:hover {
	background:#eee;
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-check-icon {
	position:absolute;
	top:23px;
	right:12px;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-row-notice {
	font-size:90%;
	color:#666;
	margin:10px 5px;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-row-notice pre {
	user-select:text;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-row-notice div {
	margin-top:10px;
	font-size:95%;
	color:#999;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn {
	display:inline-block;
	padding:8px 24px;
	border:none;
	border-radius:8px;
	color:#1e1f20;
	font-size:100%;
	background:#eee;
	cursor:pointer;
	text-decoration:none;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-link {
	color:#1d1d1d;
	font-size:95%;
	padding-right:20px;
	background:url('./Images/link.svg') no-repeat 100% 50%;
	background-size:16px;
	text-decoration:none;
	cursor:default;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-link b {
	text-decoration:underline;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-link b:hover {
	text-decoration:none;
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn:hover {
	background:#ccc;
	transition:all 1s;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn.product-edit-btn-tariff {
	padding:11px 24px;
	color:#fff;
	background:#1e1f20;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-btn.product-edit-btn-tariff:hover {
	color:#1e1f20;
	background:#ffc060;
	transition:all .3s;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images {
	display:flex;
	flex-direction:row;
	align-items:center;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image {
	position:relative;
	width:88px;
	height:88px;
	margin-right:10px;
	margin-bottom:10px;
	border-radius:12px;
	overflow:hidden;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-images .product-edit-image .delete {
	position:absolute;
	top:calc(50% - 38px / 2);
	right:calc(50% - 38px / 2);
	width:38px;
	height:38px;
	background:url('./Images/delete-black-btn.svg') no-repeat 50% 50%;
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-components {
	display:flex;
	flex-direction:row;
	align-items:center;
	flex-wrap:wrap;
	margin-bottom:10px;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin:0 10px 10px 0;
	padding:5px;
	padding-left:10px;
	border-radius:12px;
	border:1px solid #caccce;
}
.components-container .product-edit .container-inner .product-edit-row .product-edit-components .product-edit-component img {
	cursor:pointer;
}
.components-container .product-edit .container-inner .product-view-row-tariff {
	margin-top:10px;
	padding:0 15px 10px;
	border-radius:12px;
	border:1px solid #f2f2f3;
	background:#f8f8f8;
}
.components-container .product-edit .product-edit-footer {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	width:100%;
	padding:10px 0 0;
	border-top:1px solid #f2f2f3;
	background:#fff;
}
.components-container .product-edit .product-edit-footer button {
	width:49%;
	padding:14px 30px;
	border-radius:12px;
	font-size:100%;
	color:#1e1f20;
	border:none;
	cursor:pointer;
}
.components-container .product-edit .product-edit-footer .btn-cancel {
	background:#f2f2f3;
}
.components-container .product-edit .product-edit-footer .btn-save {
	background:#ffc060;
}

.components-container .product-edit .tariffs-container .tariff-tabs {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	margin:10px 0;
	border-radius:4px;
	background:#f8f8f8;
}
.components-container .product-edit .tariffs-container .tariff-tabs .tariff-tab {
	width:25%;
	padding:8px 0 8px 6px;
	font-size:85%;
	text-align:left;
	cursor:pointer;
}
.components-container .product-edit .tariffs-container .tariff-tabs .tariff-tab.tariff-tab-active {
	border-radius:4px;
	background:#eee;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:space-between;
	padding:5px;
	border-bottom:1px solid #f2f2f3;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier:nth-child(even) {
	background:#f8f8f8;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .period {
	padding-left:10px;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .price,
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .discount {
	color:#4a4d4f;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .price {
	font-size:120%;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .price b {
	color:#000;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .discount {
	font-size:90%;
	color:#999;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .pay-buttons button {
	display:block;
	margin:5px 0 10px;
	padding:4px 8px;
	color:#000;
	border:none;
	border-radius:4px;
	border:1px solid #ccc;
	background:#eee;
	cursor:pointer;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .pay-buttons button:hover {
	border-color:#ffc060;
	background:#ffc060;
	transition:.3s;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .pay-buttons div {
	margin-left:2px;
	font-size:90%;
	color:#2791fb;
	text-decoration:underline;
}
.components-container .product-edit .tariffs-container .tariff-tiers .tariff-tier .pay-buttons div:hover {
	cursor:pointer;
	text-decoration:none;
}

.components-container .product-edit .table-transactions {
	width:100%;
	margin-top:20px;
	border-collapse:collapse;
}
.components-container .product-edit .table-transactions thead {
	background:#eee;
}
.components-container .product-edit .table-transactions thead tr th {
	padding:10px 0 10px 5px;
	font-size:90%;
	text-align:left;
}
.components-container .product-edit .table-transactions tbody tr td {
	padding:10px 0 10px 5px;
	vertical-align:top;
	font-size:90%;
	color:#4a4d4f;
	border-bottom:1px solid #f2f2f3;
}
.components-container .product-edit .table-transactions tbody tr:nth-child(even) {
	background:#f8f8f8;
}
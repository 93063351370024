.components-container {
	display:flex;
	flex-direction:row;
	padding:20px;
	height:100%;
}

.components-container .categories-container {
	display:flex;
	flex-direction:row;
	width:65%;
	border-right:1px solid #f2f2f3;
}

.components-container .categories-container .products-list {
	flex:1;
}
.components-container .categories-container .products-list .products-list-controls {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.components-container .categories-container .products-list .products-list-controls input.search {
	width:100%;
	padding:14px;
	padding-left:46px;
	font-size:100%;
	border-radius:8px;
	background:#fcfcfc url('./Images/lupe.svg') no-repeat 12px center;
	border:1px solid #f2f2f3;
}
.components-container .categories-container .products-list .products-list-controls input.search:focus {
	border:1px solid #b0b3b5;
	background-color:#fff;
}
.components-container .categories-container .products-list .products-list-controls button {
	position:relative;
	top:1px;
	border:none;
	background:none;
	cursor:pointer;
}
.components-container .categories-container .products-list .products-list-controls button img {
	width:47px;
	height:47px;
}
.components-container .categories-container .products-list .container-inner {
	height:calc(100% - 70px);
	margin-top:20px;
	padding:10px;
	padding-top:0;
	padding-left:0;
	padding-right:0;
	overflow-y:auto;
	scrollbar-color:#bbb transparent;
	scrollbar-width:thin;
}
.components-container .categories-container .products-list .products-empty {
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	height:calc(100% - 60px);
}
.components-container .categories-container .products-list .products-empty div {
	margin-top:260px;
	text-align:center;
	color:#4a4d4f;
}
.components-container .categories-container .products-list .products-empty img {
	position:absolute;
	top:20px;
	right:30px;
}
.components-container .categories-container .products-list table.items {
	width:100%;
	border-collapse:collapse;
}
.components-container .categories-container .products-list table.items thead {
	position:sticky;
	top:0;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
}
.components-container .categories-container .products-list table.items thead tr th,
.components-container .categories-container .products-list table.items tr td {
	padding:10px 0 10px 5px;
	text-align:left;
}
.components-container .categories-container .products-list table.items tr td {
	border-bottom:1px solid #f2f2f3;
}
.components-container .categories-container .products-list table.items tbody tr:hover {
	background:#f2f2f3;
	cursor:pointer;
}
.components-container .categories-container .products-list table.items thead tr th {
	font-size:85%;
	font-weight:normal;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items .weight {
	width:100px;
	text-align:right;
}
.components-container .categories-container .products-list table.items .control {
	width:30px;
}
.components-container .categories-container .products-list table.items .control img {
	display:block;
	margin-left:auto;
	cursor:pointer;
}
.components-container .categories-container .products-list table.items .control .control-block {
	position:absolute;
	border-radius:8px;
	background:#fff;
	box-shadow:0 2px 8px 0 #1d1d1d1f;
	padding:10px 0;
	color:#4a4d4f;
}
.components-container .categories-container .products-list table.items .control .control-block div {
	padding:10px 50px 10px 20px;
}
.components-container .categories-container .products-list table.items .control .control-block div.edit {
	background:url('./Images/edit.svg') no-repeat center right 10px;
}
.components-container .categories-container .products-list table.items .control .control-block div.delete {
	background:url('./Images/delete-grey.svg') no-repeat center right 10px;
}
.components-container .categories-container .products-list table.items .control .control-block div:hover {
	color:#000;
	background-color:#f2f2f3;
}